import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BlogPost } from '../types/blog'
import { BlogPostList } from '../components/blog/BlogPostList'
import { BlogPost as BlogPostComponent } from '../components/blog/BlogPost'
import { blogService } from '../services/blogService'
import { BlogLayout } from '../components/layouts'

/**
 * BLOG PAGE COMPONENT
 * ==================
 * Primary blog container that manages blog posts and their display.
 *
 * DESIGN SYSTEM INTEGRATION:
 * This page is part of the global design system with consistent padding:
 * - BlogLayout provides standard px-4 content padding
 * - This component should not add additional horizontal padding
 * - Child components receive consistent width constraints
 *
 * RELATED COMPONENTS:
 * - BlogLayout (provides consistent padding and container)
 * - BlogPostList (displays grid of blog posts)
 * - BlogPost (displays single blog post)
 */

// Debug mode - set to false to disable logging
const DEBUG = false

const debug = {
  log: (...args: unknown[]) => {
    if (DEBUG) {
      console.log('[Blog Page]', ...args)
    }
  },
  error: (...args: unknown[]) => {
    if (DEBUG) {
      console.error('[Blog Page Error]', ...args)
    }
  }
}

const Blog: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const [posts, setPosts] = useState<BlogPost[]>([])
  const [currentPost, setCurrentPost] = useState<BlogPost | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        if (slug) {
          const post = await blogService.getPost(slug)
          setCurrentPost(post)
          // Also fetch the list of posts for the sidebar
          const response = await blogService.listPosts()
          setPosts(response.items)
        } else {
          const response = await blogService.listPosts()
          debug.log('Posts fetched:', response.items.length)
          setPosts(response.items)
        }
      } catch (err) {
        debug.error('Error fetching blog data:', err)
        setError('Failed to load blog content')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [slug])

  // Add SEO metadata
  useEffect(() => {
    if (!slug) {
      // Only set meta tags for the blog list page
      // Individual blog posts handle their own meta tags

      // Set page title
      document.title =
        'Developer Blog | AWS, React & TypeScript | Jeff Knowles Jr'

      // Update meta tags
      const metaTags = [
        {
          name: 'description',
          content:
            'Thoughts and insights on web development, AWS architecture, and solving technical challenges. Writing to share what I learn along the way.'
        },
        {
          property: 'og:title',
          content: 'Web Development Blog | AWS & React Insights'
        },
        {
          property: 'og:description',
          content:
            'My notes on development practices, cloud solutions and coding techniques. Join me as I explore modern web technologies and share useful discoveries.'
        },
        {
          property: 'og:url',
          content: 'https://www.jkjrdev.com/blog'
        }
      ]

      // Remove existing meta tags
      document.querySelectorAll('meta').forEach((tag) => {
        if (
          tag.getAttribute('property')?.startsWith('og:') ||
          tag.getAttribute('name') === 'description'
        ) {
          tag.remove()
        }
      })

      // Add updated meta tags
      metaTags.forEach((tag) => {
        const meta = document.createElement('meta')
        const key = Object.keys(tag).find((k) => k !== 'content') as string
        meta.setAttribute(key, tag[key as keyof typeof tag] as string)
        meta.setAttribute('content', tag.content)
        document.head.appendChild(meta)
      })

      // Update canonical link
      let canonicalLink = document.querySelector('link[rel="canonical"]')
      if (!canonicalLink) {
        canonicalLink = document.createElement('link')
        canonicalLink.setAttribute('rel', 'canonical')
        document.head.appendChild(canonicalLink)
      }
      canonicalLink.setAttribute('href', 'https://www.jkjrdev.com/blog')
    }
  }, [slug])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div className='text-red-500'>{error}</div>
  }

  return (
    <BlogLayout>
      <div className='w-full overflow-hidden'>
        {!slug && (
          <div className='mb-8'>
            <h1 className='text-4xl font-bold text-gray-900 dark:text-white mb-2'>
              Blog
            </h1>
            <p className='text-gray-600 dark:text-gray-300'>
              Explore my thoughts and insights on web development, technology,
              and more.
            </p>
          </div>
        )}
        {slug ? (
          currentPost ? (
            <BlogPostComponent post={currentPost} />
          ) : (
            <div>Post not found</div>
          )
        ) : (
          <BlogPostList posts={posts} />
        )}
      </div>
    </BlogLayout>
  )
}

export default Blog
