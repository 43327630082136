import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { BlogLayout } from '../components/layouts'

interface RouteInfo {
  path: string
  name: string
  status: 'active' | 'development' | 'experimental'
  description: string
}

const Sitemap: React.FC = () => {
  // Active routes that are properly configured in the router
  const activeRoutes: RouteInfo[] = [
    {
      path: '/',
      name: 'Home',
      status: 'active',
      description: 'Landing page with the main site content'
    },
    {
      path: '/overview',
      name: 'Overview',
      status: 'active',
      description: 'Overview page (labeled as "About" in navigation)'
    },
    {
      path: '/projects',
      name: 'Projects',
      status: 'active',
      description: 'List of projects'
    },
    {
      path: '/blog',
      name: 'Blog',
      status: 'active',
      description: 'Blog listing page with all articles'
    },
    {
      path: '/contact',
      name: 'Contact',
      status: 'active',
      description: 'Contact form and information'
    },
    {
      path: '/devlog',
      name: 'Dev Log',
      status: 'active',
      description: 'Development log with updates and changes'
    },
    {
      path: '/portfolio',
      name: 'Port Inc',
      status: 'active',
      description: 'Cosmic-themed portfolio experience'
    }
  ]

  // Dynamic routes that change based on content
  const dynamicRoutes: RouteInfo[] = [
    {
      path: '/projects/:slug',
      name: 'Project Detail',
      status: 'active',
      description: 'Individual project pages with details'
    },
    {
      path: '/blog/:slug',
      name: 'Blog Post',
      status: 'active',
      description: 'Individual blog post pages'
    }
  ]

  // Available but not linked in navigation or configured in routing
  const developmentRoutes: RouteInfo[] = [
    {
      path: '/about',
      name: 'About',
      status: 'development',
      description: 'About page (available but not linked in navigation)'
    },
    {
      path: '/aboutrelume',
      name: 'About Relume',
      status: 'development',
      description: 'Information about Relume'
    },
    {
      path: '/services',
      name: 'Services',
      status: 'development',
      description: 'Services offered'
    },
    {
      path: '/experimental',
      name: 'Experimental',
      status: 'experimental',
      description: 'Page with experimental features and components'
    },
    {
      path: '/resources',
      name: 'Resources',
      status: 'development',
      description: 'Helpful resources and links'
    }
  ]

  return (
    <BlogLayout>
      <div className='mb-6 sm:mb-8'>
        <motion.h1
          className='text-2xl sm:text-3xl md:text-4xl font-bold mb-4 text-[#52babb] dark:text-[#6ecece]'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Sitemap
        </motion.h1>
        <motion.p
          className='text-sm sm:text-base text-gray-600 dark:text-gray-300'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          A complete map of all pages and routes available on this site.
        </motion.p>
      </div>

      <motion.div
        className='space-y-8'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Active Routes Section */}
        <div className='bg-white dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6'>
          <h2 className='text-xl font-bold mb-4 text-[#52babb] dark:text-[#6ecece] border-b border-gray-200 dark:border-gray-700 pb-2'>
            Active Routes
          </h2>
          <p className='text-sm text-gray-600 dark:text-gray-400 mb-4'>
            These routes are fully implemented and accessible through the main
            navigation.
          </p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            {activeRoutes.map((route) => (
              <div
                key={route.path}
                className='bg-gray-100 dark:bg-gray-700/50 p-4 rounded-lg'
              >
                <div className='flex justify-between'>
                  <h3 className='font-medium text-[#52babb] dark:text-[#6ecece]'>
                    {route.name}
                  </h3>
                  <span className='text-xs bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300 px-2 py-0.5 rounded-full'>
                    Active
                  </span>
                </div>
                <p className='text-xs text-gray-500 dark:text-gray-400 mb-2'>
                  {route.path}
                </p>
                <p className='text-sm text-gray-600 dark:text-gray-300'>
                  {route.description}
                </p>
                <div className='mt-3'>
                  <Link
                    to={route.path.replace(':slug', 'example')}
                    className='text-sm text-[#52babb] hover:text-[#3a8384] dark:hover:text-[#8aeaea] transition-colors'
                  >
                    Visit Page →
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Dynamic Routes Section */}
        <div className='bg-white dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6'>
          <h2 className='text-xl font-bold mb-4 text-[#52babb] dark:text-[#6ecece] border-b border-gray-200 dark:border-gray-700 pb-2'>
            Dynamic Routes
          </h2>
          <p className='text-sm text-gray-600 dark:text-gray-400 mb-4'>
            These routes change based on content and use URL parameters.
          </p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            {dynamicRoutes.map((route) => (
              <div
                key={route.path}
                className='bg-gray-100 dark:bg-gray-700/50 p-4 rounded-lg'
              >
                <div className='flex justify-between'>
                  <h3 className='font-medium text-[#52babb] dark:text-[#6ecece]'>
                    {route.name}
                  </h3>
                  <span className='text-xs bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300 px-2 py-0.5 rounded-full'>
                    Dynamic
                  </span>
                </div>
                <p className='text-xs text-gray-500 dark:text-gray-400 mb-2'>
                  {route.path}
                </p>
                <p className='text-sm text-gray-600 dark:text-gray-300'>
                  {route.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Development Routes Section */}
        <div className='bg-white dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6'>
          <h2 className='text-xl font-bold mb-4 text-[#52babb] dark:text-[#6ecece] border-b border-gray-200 dark:border-gray-700 pb-2'>
            Development Routes
          </h2>
          <p className='text-sm text-gray-600 dark:text-gray-400 mb-4'>
            These routes are in development or experimental and may not be fully
            functional.
          </p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            {developmentRoutes.map((route) => (
              <div
                key={route.path}
                className='bg-gray-100 dark:bg-gray-700/50 p-4 rounded-lg'
              >
                <div className='flex justify-between'>
                  <h3 className='font-medium text-[#52babb] dark:text-[#6ecece]'>
                    {route.name}
                  </h3>
                  <span
                    className={`text-xs px-2 py-0.5 rounded-full ${
                      route.status === 'development'
                        ? 'bg-amber-100 text-amber-800 dark:bg-amber-900/30 dark:text-amber-300'
                        : 'bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300'
                    }`}
                  >
                    {route.status === 'development'
                      ? 'Development'
                      : 'Experimental'}
                  </span>
                </div>
                <p className='text-xs text-gray-500 dark:text-gray-400 mb-2'>
                  {route.path}
                </p>
                <p className='text-sm text-gray-600 dark:text-gray-300'>
                  {route.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </BlogLayout>
  )
}

export default Sitemap
