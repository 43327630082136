import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { projectService } from '../../services/projectService'
import Project from './[slug]'

const ProjectContainer: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const { isLoading, error, data } = useQuery({
    queryKey: ['project', slug],
    queryFn: () => projectService.getProject(slug!),
    enabled: !!slug
  })

  if (isLoading) {
    return (
      <div className='flex items-center justify-center min-h-screen'>
        <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500'></div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='flex items-center justify-center min-h-screen'>
        <div className='text-red-500'>
          Error loading project:{' '}
          {error instanceof Error ? error.message : 'Failed to load project'}
        </div>
      </div>
    )
  }

  if (!data) {
    return (
      <div className='flex items-center justify-center min-h-screen'>
        <div className='text-gray-500'>Project not found</div>
      </div>
    )
  }

  return <Project project={data} />
}

export default ProjectContainer
