import React from 'react'
import { BlogLayout } from '../components/layouts'
import { motion } from 'framer-motion'

const DevLog: React.FC = () => {
  return (
    <BlogLayout>
      <div className='mb-6 sm:mb-8'>
        <motion.h1
          className='text-2xl sm:text-3xl md:text-4xl font-bold mb-4 text-[#52babb] dark:text-[#6ecece]'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Development Log
        </motion.h1>
        <motion.p
          className='text-sm sm:text-base text-gray-600 dark:text-gray-300'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          A chronicle of updates, fixes, and new features for Jeff's portfolio
          site.
        </motion.p>
      </div>

      <motion.div
        className='relative'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Timeline vertical line */}
        <div className='absolute left-0 sm:left-1/2 h-full w-0.5 bg-[#52babb]/30 dark:bg-[#6ecece]/30 transform sm:-translate-x-1/2'></div>

        {/* April 2, 2025 Entry */}
        <div className='mb-12 relative'>
          <div className='flex flex-col sm:flex-row items-start'>
            {/* Timeline date for desktop */}
            <div className='hidden sm:block absolute right-[50%] mr-3 mt-2.5 z-10'>
              <div className='bg-white dark:bg-gray-800 text-sm font-medium text-[#52babb] dark:text-[#6ecece] py-1 px-2 rounded shadow-sm border border-gray-100 dark:border-gray-700'>
                April 2, 2025
              </div>
            </div>

            {/* Timeline dot */}
            <div className='absolute left-0 sm:left-1/2 w-5 h-5 rounded-full bg-[#52babb] dark:bg-[#6ecece] transform -translate-x-1/2 border-4 border-white dark:border-gray-800 z-20'></div>

            {/* Empty space on left side for desktop */}
            <div className='hidden sm:block w-1/2'></div>

            {/* Timeline content */}
            <div className='pt-0.5 pl-8 sm:pl-8 sm:w-1/2'>
              {/* Timeline date for mobile */}
              <div className='sm:hidden text-base font-bold text-[#52babb] dark:text-[#6ecece] mb-2'>
                April 2, 2025
              </div>

              <div className='bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md border border-gray-100 dark:border-gray-700'>
                <div className='space-y-4'>
                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Services to Resources Transformation
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Converted the Services page into a comprehensive Resources
                      page featuring development documentation, design tools,
                      and learning resources. The new layout maintains the
                      modern three-column design with responsive behavior.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Navigation Overhaul</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Updated route configuration in ThemedApp.tsx, removing the
                      '/services' route and changing '/overview' to '/about' for
                      improved navigation structure. Navigation menu text was
                      also updated from "Overview" to "About" to match the route
                      change.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Resource Content Integration
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Integrated relevant resources from the existing
                      cosmic-themed Resources page into a new modern design.
                      Created resource cards with hover effects and detailed
                      descriptions, organized by category for better user
                      experience.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Code Refactoring</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Removed dependencies on Three.js and GSAP libraries from
                      the Resources page, simplifying the codebase and improving
                      performance. Fixed various linting errors for better code
                      quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* April 1, 2025 Entry */}
        <div className='mb-12 relative'>
          <div className='flex flex-col sm:flex-row items-start'>
            {/* Timeline date for desktop */}
            <div className='hidden sm:block absolute right-[50%] mr-3 mt-2.5 z-10'>
              <div className='bg-white dark:bg-gray-800 text-sm font-medium text-[#52babb] dark:text-[#6ecece] py-1 px-2 rounded shadow-sm border border-gray-100 dark:border-gray-700'>
                April 1, 2025
              </div>
            </div>

            {/* Timeline dot */}
            <div className='absolute left-0 sm:left-1/2 w-5 h-5 rounded-full bg-[#52babb] dark:bg-[#6ecece] transform -translate-x-1/2 border-4 border-white dark:border-gray-800 z-20'></div>

            {/* Empty space on left side for desktop */}
            <div className='hidden sm:block w-1/2'></div>

            {/* Timeline content */}
            <div className='pt-0.5 pl-8 sm:pl-8 sm:w-1/2'>
              {/* Timeline date for mobile */}
              <div className='sm:hidden text-base font-bold text-[#52babb] dark:text-[#6ecece] mb-2'>
                April 1, 2025
              </div>

              <div className='bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md border border-gray-100 dark:border-gray-700'>
                <div className='space-y-4'>
                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      5 Year Developer Anniversary
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Today marks the 5 year anniversary of my journey as a
                      professional developer after graduating bootcamp and
                      starting at Model B. A significant milestone worth
                      celebrating!
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Portfolio Experience Added</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Implemented a new immersive "Port Inc" cosmic-themed
                      portfolio experience. Added proper routing in
                      ThemedApp.tsx and updated the footer with a link to this
                      new section.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Footer Navigation Enhancement
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Updated the footer component with improved navigation
                      links including the new "Port Inc" cosmic portfolio
                      experience, making it more accessible to visitors.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Sidebar Change Dance</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Performed the classic developer two-step: made changes to
                      the sidebar, rolled them back, made more changes, then
                      rolled those back too. Sometimes the best changes are the
                      ones we decide not to keep - it's all part of the
                      adventure! Ya never know.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* March 30, 2025 Entry */}
        <div className='mb-12 relative'>
          <div className='flex flex-col sm:flex-row items-start'>
            {/* Timeline date for desktop */}
            <div className='hidden sm:block absolute right-[50%] mr-3 mt-2.5 z-10'>
              <div className='bg-white dark:bg-gray-800 text-sm font-medium text-[#52babb] dark:text-[#6ecece] py-1 px-2 rounded shadow-sm border border-gray-100 dark:border-gray-700'>
                March 30, 2025
              </div>
            </div>

            {/* Timeline dot */}
            <div className='absolute left-0 sm:left-1/2 w-5 h-5 rounded-full bg-[#52babb] dark:bg-[#6ecece] transform -translate-x-1/2 border-4 border-white dark:border-gray-800 z-20'></div>

            {/* Empty space on left side for desktop */}
            <div className='hidden sm:block w-1/2'></div>

            {/* Timeline content */}
            <div className='pt-0.5 pl-8 sm:pl-8 sm:w-1/2'>
              {/* Timeline date for mobile */}
              <div className='sm:hidden text-base font-bold text-[#52babb] dark:text-[#6ecece] mb-2'>
                March 30, 2025
              </div>

              <div className='bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md border border-gray-100 dark:border-gray-700'>
                <div className='space-y-4'>
                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Full Viewport Animation</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Enhanced the background animation to span the entire
                      viewport by implementing fixed positioning, CSS viewport
                      height variables, and proper bottom anchoring to ensure
                      complete coverage on all devices and browsers.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Component Documentation System
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Implemented bilateral linking documentation between
                      related components, creating a network of references that
                      developers can follow to understand component
                      relationships.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Animation Performance Optimization
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Improved animation performance by optimizing canvas
                      rendering and adding device-specific optimizations for
                      mobile displays.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Canvas Scaling for High-DPI Displays
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Enhanced the LineArtBackground component to properly scale
                      for high-DPI displays, ensuring crisp visuals across all
                      devices.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Z-Index Layering System</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Implemented a consistent z-index layering system to ensure
                      proper stacking of UI elements over the background
                      animation.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Design System Documentation</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Implemented comprehensive design system documentation
                      across layout components to ensure consistent padding and
                      spacing through a new PADDING_SYSTEM.md guide.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      BlogList Padding Consistency
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Fixed padding inconsistencies in the BlogPostList
                      component to match the global layout system, ensuring
                      proper alignment with standard container padding classes.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Mobile Sidebar Behavior</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Enhanced mobile UX by automatically closing the sidebar
                      when a navigation link is clicked, providing a smoother
                      navigation experience on smaller devices.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Content Legibility Improvement
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Increased text size for project and blog post links in the
                      sidebar to improve readability, with responsive
                      adjustments for different screen sizes.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Synchronized Sidebar Scrolling
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Improved desktop UX by synchronizing sidebar scrolling
                      with the main content area. This ensures visual
                      consistency as users scroll down pages, with sidebar
                      elements remaining aligned with the main content flow.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Mobile Scrolling Enhancement
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Fixed mobile scrolling issues by removing unnecessary
                      overflow restrictions across layout components. Content
                      now scrolls smoothly on mobile devices without
                      interference from layout constraints.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Footer Positioning Fix</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Resolved an issue where the footer could appear above
                      content by adding proper z-index layering and minimum
                      height requirements to ensure the footer always stays at
                      the bottom of the viewport.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Sidebar Icons Enhancement</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Redesigned sidebar with two distinct navigation styles:
                      main menu items have icons on the right for a modern look,
                      while project and blog items maintain traditional
                      left-aligned icons. All icons now feature larger sizes and
                      a vibrant color scheme for improved visual hierarchy.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Animated NavMenu Component</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Introduced a new dedicated NavMenu component with
                      touch-responsive animations. Added smart device detection
                      to enable right-to-left swipe animations only on touch
                      screen devices, while maintaining simple hover effects for
                      desktop users.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Sidebar Positioning Update</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Changed sidebar positioning from fixed/sticky to relative
                      for a more natural page flow and adjusted to full height
                      to match the background consistently. This improves the
                      scrolling experience by allowing the sidebar to move
                      naturally with the content while maintaining visual
                      continuity.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Overview Mobile Layout Fix</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Updated technical stack cards in the Overview page to have
                      full row width on mobile devices, improving readability
                      and providing a better visual layout on smaller screens.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* March 29, 2025 Entry */}
        <div className='mb-12 relative'>
          <div className='flex flex-col sm:flex-row items-start'>
            {/* Timeline date for desktop */}
            <div className='hidden sm:block absolute right-[50%] mr-3 mt-2.5 z-10'>
              <div className='bg-white dark:bg-gray-800 text-sm font-medium text-[#52babb] dark:text-[#6ecece] py-1 px-2 rounded shadow-sm border border-gray-100 dark:border-gray-700'>
                March 29, 2025
              </div>
            </div>

            {/* Timeline dot */}
            <div className='absolute left-0 sm:left-1/2 w-5 h-5 rounded-full bg-[#52babb] dark:bg-[#6ecece] transform -translate-x-1/2 border-4 border-white dark:border-gray-800 z-20'></div>

            {/* Empty space on left side for desktop */}
            <div className='hidden sm:block w-1/2'></div>

            {/* Timeline content */}
            <div className='pt-0.5 pl-8 sm:pl-8 sm:w-1/2'>
              {/* Timeline date for mobile */}
              <div className='sm:hidden text-base font-bold text-[#52babb] dark:text-[#6ecece] mb-2'>
                March 29, 2025
              </div>

              <div className='bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md border border-gray-100 dark:border-gray-700'>
                <div className='space-y-4'>
                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Author Attribution Update</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Changed author name in all blog posts to "Jeff & Claude"
                      to recognize the collaborative nature of the content
                      creation.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Dev Log Creation</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Added this Development Log page with a timeline layout to
                      track changes and improvements to the site.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Mobile View Optimization</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Improved mobile responsiveness across the site,
                      particularly in BlogLayout and Blog components with better
                      padding and responsive typography.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Blog Component Enhancements</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Updated Blog component header with responsive text sizes
                      and properly scaled spacing between elements for mobile
                      devices.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>
                      Blog Post Component Enhancement
                    </h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Improved blog post component with responsive typography,
                      better spacing, and fixed code block rendering for small
                      screens.
                    </p>
                  </div>

                  <div className='border-l-4 border-[#52babb] dark:border-[#6ecece] pl-4'>
                    <h3 className='font-medium'>Blog Layout Optimization</h3>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Adjusted container padding and width settings for mobile
                      devices, ensuring responsive padding that increases with
                      screen size.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </BlogLayout>
  )
}

export default DevLog
