import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTheme } from '../contexts/ThemeContext'
import { submitContactForm } from '../services/api'

// Section header component
interface SectionHeaderProps {
  title: string
  subtitle: string
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subtitle }) => {
  return (
    <motion.section
      className='text-center mb-8'
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className='text-2xl font-bold'>{title}</h2>
      <p className='max-w-2xl mx-auto mt-2 text-gray-600 dark:text-gray-300'>
        {subtitle}
      </p>
    </motion.section>
  )
}

interface FormData {
  name: string
  email: string
  service: string
  message: string
}

const Contact: React.FC = () => {
  const { theme } = useTheme()
  const [isFormVisible, setIsFormVisible] = useState(() => {
    const savedState = localStorage.getItem('contactFormVisible')
    return savedState === null ? true : savedState === 'true'
  })
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    service: '',
    message: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)

  // Add SEO metadata
  useEffect(() => {
    // Set page title
    document.title =
      'Contact Jeff Knowles Jr | Web Developer & AWS Solutions Architect'

    // Update meta tags
    const metaTags = [
      {
        name: 'description',
        content:
          "Interested in working together? Reach out and let me know what you're looking to build. I'm always open to discussing new projects or collaboration opportunities."
      },
      {
        property: 'og:title',
        content: 'Contact | Jeff Knowles Jr | Web Development Services'
      },
      {
        property: 'og:description',
        content:
          "Let's talk about your web development needs. I provide React development, AWS architecture, and full-stack solutions tailored to your specific requirements."
      },
      {
        property: 'og:url',
        content: 'https://www.jkjrdev.com/contact'
      }
    ]

    // Remove existing meta tags
    document.querySelectorAll('meta').forEach((tag) => {
      if (
        tag.getAttribute('property')?.startsWith('og:') ||
        tag.getAttribute('name') === 'description'
      ) {
        tag.remove()
      }
    })

    // Add updated meta tags
    metaTags.forEach((tag) => {
      const meta = document.createElement('meta')
      const key = Object.keys(tag).find((k) => k !== 'content') as string
      meta.setAttribute(key, tag[key as keyof typeof tag] as string)
      meta.setAttribute('content', tag.content)
      document.head.appendChild(meta)
    })

    // Update canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]')
    if (!canonicalLink) {
      canonicalLink = document.createElement('link')
      canonicalLink.setAttribute('rel', 'canonical')
      document.head.appendChild(canonicalLink)
    }
    canonicalLink.setAttribute('href', 'https://www.jkjrdev.com/contact')
  }, [])

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    setError(null)

    if (!captchaToken) {
      setError('Please complete the reCAPTCHA verification.')
      setIsSubmitting(false)
      return
    }

    try {
      await submitContactForm({
        name: formData.name,
        email: formData.email,
        message: `Service: ${formData.service}\n\n${formData.message}`
      })
      setFormData({ name: '', email: '', service: '', message: '' })
      setIsSubmitting(false)
      setIsSuccess(true)
    } catch (error) {
      console.error('Error submitting form:', error)
      setError('Failed to submit form. Please try again.')
      setIsSubmitting(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault()
      const formEvent = { preventDefault: () => {} } as React.FormEvent
      handleSubmit(formEvent)
    }
  }

  const handleCloseForm = () => {
    setIsFormVisible(false)
    localStorage.setItem('contactFormVisible', 'false')
  }

  return (
    <div className='container mx-auto px-page-mobile md:px-page-desktop py-12'>
      <div className='max-w-6xl mx-auto'>
        <SectionHeader
          title='Get in Touch'
          subtitle="Have a project in mind? Fill out the form below and I'll get back to you within 24 hours."
        />

        {isFormVisible && (
          <motion.div
            className='bg-white dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 border border-gray-200 dark:border-gray-700 text-gray-800 dark:text-white rounded-lg shadow-md backdrop-blur-sm p-8 mb-12'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1, duration: 0.5 }}
          >
            <div className='max-w-2xl mx-auto'>
              <div className='flex justify-end mb-4'>
                <button
                  onClick={handleCloseForm}
                  className='text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors'
                  aria-label='Close form'
                >
                  <i className='fas fa-times text-lg'></i>
                </button>
              </div>
              <form
                className='space-y-6'
                onSubmit={handleSubmit}
                onKeyDown={handleKeyDown}
              >
                <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                  <div>
                    <label
                      htmlFor='name'
                      className='block text-sm font-medium mb-2'
                    >
                      Name
                    </label>
                    <input
                      type='text'
                      id='name'
                      name='name'
                      value={formData.name}
                      onChange={handleChange}
                      placeholder='Your name'
                      className='w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 bg-opacity-70 dark:bg-opacity-70 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-primary'
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium mb-2'
                    >
                      Email
                    </label>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      placeholder='your.email@example.com'
                      className='w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 bg-opacity-70 dark:bg-opacity-70 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-primary'
                      required
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='service'
                    className='block text-sm font-medium mb-2'
                  >
                    Service Needed
                  </label>
                  <select
                    id='service'
                    name='service'
                    value={formData.service}
                    onChange={handleChange}
                    className='w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 bg-opacity-70 dark:bg-opacity-70 text-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary [&>option]:text-black dark:[&>option]:text-white dark:[&>option]:bg-gray-800 [&>option]:bg-white'
                    required
                  >
                    <option value=''>Select a service</option>
                    <option value='react'>React Development</option>
                    <option value='aws'>AWS Solutions</option>
                    <option value='fullstack'>Full Stack Development</option>
                    <option value='mobile'>React Native Development</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor='message'
                    className='block text-sm font-medium mb-2'
                  >
                    Message
                  </label>
                  <textarea
                    id='message'
                    name='message'
                    value={formData.message}
                    onChange={handleChange}
                    rows={5}
                    placeholder='Tell me about your project'
                    className='w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 bg-opacity-70 dark:bg-opacity-70 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-primary'
                    required
                  ></textarea>
                </div>

                <div className='flex justify-center my-4'>
                  <ReCAPTCHA
                    sitekey='6LdoufYqAAAAAAxLo7O9i_DNcdbaWACZu6bc0q_y'
                    onChange={handleCaptchaChange}
                    theme={theme === 'dark' ? 'dark' : 'light'}
                  />
                </div>

                {error && (
                  <div className='text-red-500 text-sm text-center'>
                    {error}
                  </div>
                )}

                {isSuccess && (
                  <div className='text-green-500 text-sm text-center'>
                    Your message has been sent successfully!
                  </div>
                )}

                <div className='flex justify-end'>
                  <button
                    type='submit'
                    disabled={isSubmitting || !captchaToken}
                    className={`px-6 py-2 ${
                      isSubmitting || !captchaToken
                        ? 'bg-primary/50 cursor-not-allowed'
                        : 'bg-primary hover:bg-primary-dark'
                    } text-white font-medium rounded-lg transition-colors duration-200`}
                  >
                    {isSubmitting ? (
                      <span className='flex items-center justify-center'>
                        <i className='fas fa-circle-notch fa-spin mr-2'></i>
                        Sending...
                      </span>
                    ) : (
                      <>
                        <i className='fas fa-paper-plane mr-2'></i>
                        Send Message
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        )}

        <SectionHeader
          title='Other Ways to Connect'
          subtitle='Feel free to reach out through any of these channels'
        />

        <motion.div
          className='grid grid-cols-1 md:grid-cols-3 gap-8 mb-12'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {/* Email Card */}
          <div className='bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 border-t-4 border-primary hover:shadow-lg transition-shadow duration-300'>
            <div className='text-primary text-3xl mb-4 flex justify-center'>
              <div className='w-14 h-14 rounded-full bg-primary/10 flex items-center justify-center'>
                <i className='fas fa-envelope text-primary'></i>
              </div>
            </div>
            <h3 className='text-lg font-bold mb-3 text-center'>Email</h3>
            <div className='text-center'>
              <a
                href='mailto:hello@jeffknowlesjr.com'
                className='hover:text-primary dark:hover:text-primary inline-block break-words w-full'
              >
                hello@jeffknowlesjr.com
              </a>
            </div>
          </div>

          {/* LinkedIn Card */}
          <div className='bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 border-t-4 border-[#0A66C2] hover:shadow-lg transition-shadow duration-300'>
            <div className='text-[#0A66C2] text-3xl mb-4 flex justify-center'>
              <div className='w-14 h-14 rounded-full bg-[#0A66C2]/10 flex items-center justify-center'>
                <i className='fab fa-linkedin text-[#0A66C2]'></i>
              </div>
            </div>
            <h3 className='text-lg font-bold mb-3 text-center'>LinkedIn</h3>
            <div className='text-center'>
              <a
                href='https://linkedin.com/in/jeffknowlesjr'
                target='_blank'
                rel='noopener noreferrer'
                className='hover:text-[#0A66C2] dark:hover:text-[#0A66C2]'
              >
                Connect on LinkedIn
              </a>
            </div>
          </div>

          {/* GitHub Card */}
          <div className='bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 border-t-4 border-gray-900 dark:border-white hover:shadow-lg transition-shadow duration-300'>
            <div className='text-gray-900 dark:text-white text-3xl mb-4 flex justify-center'>
              <div className='w-14 h-14 rounded-full bg-gray-900/10 dark:bg-white/10 flex items-center justify-center'>
                <i className='fab fa-github text-gray-900 dark:text-white'></i>
              </div>
            </div>
            <h3 className='text-lg font-bold mb-3 text-center'>GitHub</h3>
            <div className='text-center'>
              <a
                href='https://github.com/jeffknowlesjr'
                target='_blank'
                rel='noopener noreferrer'
                className='hover:text-gray-900 dark:hover:text-white'
              >
                View Projects
              </a>
            </div>
          </div>
        </motion.div>

        <SectionHeader
          title='FAQ'
          subtitle='Common questions about working together'
        />

        <motion.div
          className='bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden mb-12'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <div className='divide-y divide-gray-200 dark:divide-gray-700'>
            {/* FAQ Item 1 */}
            <div className='p-6'>
              <div className='flex items-start'>
                <div className='text-primary mr-4 mt-1 float-left'>
                  <i className='fas fa-question-circle text-xl'></i>
                </div>
                <div className='overflow-hidden'>
                  <h3 className='text-lg font-bold mb-2'>
                    What is your typical response time?
                  </h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    I aim to respond to all inquiries within 24 hours during
                    business days. For urgent matters, I'm often able to reply
                    much sooner.
                  </p>
                </div>
              </div>
            </div>

            {/* FAQ Item 2 */}
            <div className='p-6'>
              <div className='flex items-start'>
                <div className='text-primary mr-4 mt-1 float-left'>
                  <i className='fas fa-question-circle text-xl'></i>
                </div>
                <div className='overflow-hidden'>
                  <h3 className='text-lg font-bold mb-2'>
                    What are your payment terms?
                  </h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Payment terms are typically 50% upfront and 50% upon
                    completion for projects. Monthly retainers are billed at the
                    beginning of each month. I accept payments via bank
                    transfer, PayPal, and credit card.
                  </p>
                </div>
              </div>
            </div>

            {/* FAQ Item 3 */}
            <div className='p-6'>
              <div className='flex items-start'>
                <div className='text-primary mr-4 mt-1 float-left'>
                  <i className='fas fa-question-circle text-xl'></i>
                </div>
                <div className='overflow-hidden'>
                  <h3 className='text-lg font-bold mb-2'>
                    What is your development process?
                  </h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    My development process typically involves an initial
                    consultation, project planning, regular progress updates,
                    and thorough testing before delivery. I emphasize clear
                    communication throughout the entire process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Contact
