import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Project as ProjectType } from '../../types/project'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import { ProjectLayout } from '../../components/layouts'

interface ProjectProps {
  project: ProjectType & {
    // SEO-related optional fields
    description?: string
    ogTitle?: string
    ogDescription?: string
    ogImage?: string
    ogType?: string
    keywords?: string[]
    datePublished?: string
    dateModified?: string
    articleSection?: string
    articleAuthor?: string
    twitterCard?: string
    twitterCreator?: string
  }
}

const Project: React.FC<ProjectProps> = ({ project }) => {
  const { slug } = useParams<{ slug: string }>()
  const baseUrl = import.meta.env.VITE_BASE_URL || 'https://jeffknowlesjr.com'
  const canonicalUrl = `${baseUrl}/projects/${slug}`

  // Format the structured data for JSON-LD
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: project.title,
    description: project.description || project.excerpt,
    author: {
      '@type': 'Person',
      name: 'Jeff Knowles'
    },
    datePublished: project.datePublished || project.publishedAt,
    dateModified:
      project.dateModified || project.updatedAt || project.publishedAt,
    publisher: {
      '@type': 'Organization',
      name: 'Jeff Knowles Jr Digital Development',
      logo: {
        '@type': 'ImageObject',
        url: `${baseUrl}/images/logo.png`
      }
    },
    image: [`${baseUrl}${project.ogImage || project.featuredImage || ''}`],
    url: canonicalUrl,
    mainEntityOfPage: canonicalUrl,
    keywords: project.keywords?.join(', ') || project.tags.join(', '),
    applicationCategory: project.projectType,
    operatingSystem: 'Web',
    offers: project.liveUrl
      ? {
          '@type': 'Offer',
          url: project.liveUrl
        }
      : undefined,
    codeRepository: project.githubUrl
  }

  // Update document head with SEO metadata
  useEffect(() => {
    // Set page title
    document.title = project.ogTitle || project.title

    // Update meta tags
    const metaTags = [
      { name: 'description', content: project.description || project.excerpt },
      {
        name: 'keywords',
        content: project.keywords?.join(', ') || project.tags.join(', ')
      },
      { property: 'og:title', content: project.ogTitle || project.title },
      {
        property: 'og:description',
        content: project.ogDescription || project.description || project.excerpt
      },
      {
        property: 'og:image',
        content: `${baseUrl}${project.ogImage || project.featuredImage || ''}`
      },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:type', content: project.ogType || 'website' },
      {
        name: 'twitter:card',
        content: project.twitterCard || 'summary_large_image'
      },
      {
        name: 'twitter:creator',
        content: project.twitterCreator || '@jeffknowlesjr'
      },
      { property: 'article:section', content: project.articleSection || '' },
      {
        property: 'article:author',
        content: project.articleAuthor || project.author
      },
      {
        property: 'article:published_time',
        content: project.datePublished || project.publishedAt
      },
      {
        property: 'article:modified_time',
        content:
          project.dateModified || project.updatedAt || project.publishedAt
      }
    ]

    // Remove existing meta tags
    document.querySelectorAll('meta').forEach((tag) => {
      if (
        tag.getAttribute('property')?.startsWith('og:') ||
        tag.getAttribute('property')?.startsWith('article:') ||
        tag.getAttribute('name')?.startsWith('twitter:') ||
        tag.getAttribute('name') === 'description' ||
        tag.getAttribute('name') === 'keywords'
      ) {
        tag.remove()
      }
    })

    // Add updated meta tags
    metaTags.forEach((tag) => {
      const meta = document.createElement('meta')
      const key = Object.keys(tag).find((k) => k !== 'content') as string
      meta.setAttribute(key, tag[key as keyof typeof tag] as string)
      meta.setAttribute('content', tag.content)
      document.head.appendChild(meta)
    })

    // Update canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]')
    if (!canonicalLink) {
      canonicalLink = document.createElement('link')
      canonicalLink.setAttribute('rel', 'canonical')
      document.head.appendChild(canonicalLink)
    }
    canonicalLink.setAttribute('href', canonicalUrl)

    // Add JSON-LD structured data
    let scriptTag = document.querySelector('script[type="application/ld+json"]')
    if (!scriptTag) {
      scriptTag = document.createElement('script')
      scriptTag.setAttribute('type', 'application/ld+json')
      document.head.appendChild(scriptTag)
    }
    scriptTag.textContent = JSON.stringify(structuredData)

    // Cleanup function
    return () => {
      // Remove JSON-LD script on unmount
      document.querySelector('script[type="application/ld+json"]')?.remove()
    }
  }, [project, canonicalUrl, baseUrl])

  return (
    <ProjectLayout>
      <div className='w-full max-w-full overflow-x-hidden'>
        <article className='mx-auto max-w-screen-xl py-4 sm:py-8 project-page overflow-hidden w-full'>
          <header className='mb-6 sm:mb-8 overflow-hidden'>
            <h1 className='mb-4 text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 dark:text-gray-50 break-words'>
              {project.title}
            </h1>
            <div className='space-y-3'>
              <div className='flex flex-wrap items-center gap-2 sm:gap-4 text-sm text-gray-600 dark:text-gray-300'>
                <span className='break-words'>{project.author}</span>
                <span>•</span>
                <span className='break-words'>
                  {new Date(project.publishedAt).toLocaleDateString()}
                </span>
                <span>•</span>
                <span className='break-words'>{project.projectType}</span>
                <span>•</span>
                <span className='break-words'>{project.projectStatus}</span>
              </div>
              <div className='flex flex-wrap gap-2 pt-1'>
                {project.tags.map((tag) => (
                  <span
                    key={tag}
                    className='rounded-full bg-gray-200 dark:bg-gray-700 px-2 sm:px-3 py-1 text-xs sm:text-sm text-gray-700 dark:text-gray-200 break-words'
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </header>

          {project.featuredImage && (
            <div className='relative mb-6 sm:mb-8 w-full overflow-hidden'>
              <img
                src={project.featuredImage}
                alt={project.title}
                className='w-full h-auto rounded-lg object-cover'
                style={{ maxWidth: '100%' }}
              />
            </div>
          )}

          <div
            className='prose prose-base sm:prose-lg dark:prose-invert max-w-none overflow-x-hidden
            prose-headings:text-gray-900 dark:prose-headings:text-gray-50 prose-headings:break-words
            prose-p:text-gray-700 dark:prose-p:text-gray-200 prose-p:break-words
            prose-strong:text-gray-900 dark:prose-strong:text-gray-50
            prose-ul:text-gray-700 dark:prose-ul:text-gray-200
            prose-ol:text-gray-700 dark:prose-ol:text-gray-200
            prose-li:text-gray-700 dark:prose-li:text-gray-200 prose-li:break-words
            prose-a:text-primary hover:prose-a:text-primary-dark dark:prose-a:text-primary-light dark:hover:prose-a:text-primary prose-a:break-words
            prose-code:text-gray-800 dark:prose-code:text-gray-100
            prose-pre:bg-gray-100 dark:prose-pre:bg-gray-800
            prose-blockquote:text-gray-700 dark:prose-blockquote:text-gray-200
            prose-hr:border-gray-300 dark:prose-hr:border-gray-600
            prose-img:rounded-lg prose-img:mx-auto prose-img:max-w-full
            [&_table]:w-full [&_table]:overflow-hidden [&_table]:border-collapse
            [&_pre]:overflow-x-auto [&_pre]:max-w-full'
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw, rehypeSanitize]}
              components={{
                // Override heading components to prevent automatic ID generation
                // which might be causing table of contents rendering
                h1: (props) => <h1 {...props} />,
                h2: (props) => <h2 {...props} />,
                h3: (props) => <h3 {...props} />,
                h4: (props) => <h4 {...props} />,
                h5: (props) => <h5 {...props} />,
                h6: (props) => <h6 {...props} />,
                // Add components to handle wide content
                pre: ({ children, ...props }) => (
                  <div
                    className='overflow-x-auto w-full'
                    style={{ maxWidth: '100%' }}
                  >
                    <pre {...props}>{children}</pre>
                  </div>
                ),
                table: ({ children, ...props }) => (
                  <div
                    className='overflow-x-auto w-full'
                    style={{ maxWidth: '100%' }}
                  >
                    <table {...props}>{children}</table>
                  </div>
                ),
                img: ({ src, alt }) => (
                  <div className='w-full overflow-hidden'>
                    <img
                      src={src}
                      alt={alt || ''}
                      className='w-full h-auto rounded-lg object-cover max-w-full'
                      style={{ maxWidth: '100%' }}
                    />
                  </div>
                )
              }}
            >
              {project.content}
            </ReactMarkdown>
          </div>

          <footer className='mt-6 pt-6 border-t border-gray-200 dark:border-gray-700 overflow-hidden'>
            <div className='flex flex-wrap gap-2 mb-4'>
              <h3 className='text-lg font-bold text-gray-900 dark:text-white w-full mb-2 break-words'>
                Technologies Used
              </h3>
              {project.techStack.map((tech) => (
                <span
                  key={tech}
                  className='px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100 rounded-full text-sm break-words'
                >
                  {tech}
                </span>
              ))}
            </div>
            <div className='mt-4 flex flex-wrap gap-4'>
              {project.githubUrl && (
                <a
                  href={project.githubUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-600 dark:text-blue-400 hover:underline flex items-center break-words'
                >
                  <i className='fab fa-github mr-2'></i> View on GitHub
                </a>
              )}
              {project.liveUrl && (
                <a
                  href={project.liveUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-600 dark:text-blue-400 hover:underline flex items-center break-words'
                >
                  <i className='fas fa-external-link-alt mr-2'></i> Live Demo
                </a>
              )}
            </div>
          </footer>
        </article>
      </div>
    </ProjectLayout>
  )
}

export default Project
