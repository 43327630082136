import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import * as THREE from 'three'
import gsap from 'gsap'

const Portfolio = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const sceneRef = useRef<THREE.Scene | null>(null)
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null)
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null)
  const starsRef = useRef<THREE.Points | null>(null)
  const nebulaRef = useRef<THREE.Mesh | null>(null)
  const portalRef = useRef<THREE.Mesh | null>(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const mousePositionRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 })
  const [glitchText, setGlitchText] = useState(false)
  const [scrambledText, setScrambledText] = useState('')
  const originalText = 'The Void Between Creation'
  const glitchTimerRef = useRef<NodeJS.Timeout | null>(null)
  const particlesRef = useRef<
    Array<{
      x: number
      y: number
      size: number
      speedX: number
      speedY: number
      opacity: number
      hue: number
    }>
  >([])
  const particleCanvasRef = useRef<HTMLCanvasElement>(null)
  const animationFrameIdRef = useRef<number | null>(null)

  // Track mouse position for particle interaction
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      mousePositionRef.current = {
        x: e.clientX,
        y: e.clientY
      }
    }

    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  // Set up glitch text effect
  useEffect(() => {
    const startGlitchLoop = () => {
      // Randomize the interval between glitches
      const nextGlitch = 2000 + Math.random() * 8000

      glitchTimerRef.current = setTimeout(() => {
        setGlitchText(true)

        // Generate scrambled text
        let result = ''
        const characters =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-=_+[]{}|;:,.<>/?`~'
        const originalChars = originalText.split('')

        originalChars.forEach((char) => {
          // 70% chance to keep original character
          if (Math.random() > 0.3) {
            result += char
          } else {
            // 30% chance to use a random character
            result += characters.charAt(
              Math.floor(Math.random() * characters.length)
            )
          }
        })

        setScrambledText(result)

        // Turn off glitch after a short time
        setTimeout(() => {
          setGlitchText(false)
          startGlitchLoop()
        }, 150)
      }, nextGlitch)
    }

    startGlitchLoop()

    return () => {
      if (glitchTimerRef.current) {
        clearTimeout(glitchTimerRef.current)
      }
    }
  }, [])

  // Initialize particle effect
  useEffect(() => {
    if (!particleCanvasRef.current) return

    const canvas = particleCanvasRef.current
    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Set canvas dimensions
    const setCanvasDimensions = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }

    setCanvasDimensions()
    window.addEventListener('resize', setCanvasDimensions)

    // Create particles
    const createParticles = () => {
      const particles = []
      const particleCount = Math.floor(
        (window.innerWidth * window.innerHeight) / 20000
      )

      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * 2 + 0.5,
          speedX: (Math.random() - 0.5) * 0.5,
          speedY: (Math.random() - 0.5) * 0.5,
          opacity: Math.random() * 0.5 + 0.3,
          hue: Math.random() > 0.5 ? 280 : 340 // Purple or pink hues
        })
      }

      particlesRef.current = particles
    }

    // Animation loop for particles
    const animateParticles = () => {
      if (!ctx) return

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      const particles = particlesRef.current
      const mousePosition = mousePositionRef.current

      for (let i = 0; i < particles.length; i++) {
        const p = particles[i]

        // Update position
        p.x += p.speedX
        p.y += p.speedY

        // Wrap around edges
        if (p.x > canvas.width) p.x = 0
        if (p.x < 0) p.x = canvas.width
        if (p.y > canvas.height) p.y = 0
        if (p.y < 0) p.y = canvas.height

        // Mouse interaction
        const dx = mousePosition.x - p.x
        const dy = mousePosition.y - p.y
        const distance = Math.sqrt(dx * dx + dy * dy)

        if (distance < 150) {
          // Repel particles from mouse
          const angle = Math.atan2(dy, dx)
          const force = (150 - distance) / 1500
          p.speedX -= Math.cos(angle) * force
          p.speedY -= Math.sin(angle) * force

          // Limit speed
          const maxSpeed = 2
          const currentSpeed = Math.sqrt(
            p.speedX * p.speedX + p.speedY * p.speedY
          )
          if (currentSpeed > maxSpeed) {
            p.speedX = (p.speedX / currentSpeed) * maxSpeed
            p.speedY = (p.speedY / currentSpeed) * maxSpeed
          }
        }

        // Draw particle
        ctx.beginPath()
        ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2)
        ctx.fillStyle = `hsla(${p.hue}, 100%, 75%, ${p.opacity})`
        ctx.fill()

        // Connect nearby particles
        for (let j = i + 1; j < particles.length; j++) {
          const p2 = particles[j]
          const dx = p.x - p2.x
          const dy = p.y - p2.y
          const distance = Math.sqrt(dx * dx + dy * dy)

          if (distance < 100) {
            ctx.beginPath()
            ctx.strokeStyle = `hsla(${(p.hue + p2.hue) / 2}, 100%, 75%, ${
              0.15 * (1 - distance / 100)
            })`
            ctx.lineWidth = 0.5
            ctx.moveTo(p.x, p.y)
            ctx.lineTo(p2.x, p2.y)
            ctx.stroke()
          }
        }
      }

      animationFrameIdRef.current = requestAnimationFrame(animateParticles)
    }

    createParticles()
    animateParticles()

    return () => {
      window.removeEventListener('resize', setCanvasDimensions)
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current)
      }
    }
  }, [])

  // Initialize Three.js scene
  useEffect(() => {
    if (!canvasRef.current) return

    // Create scene
    const scene = new THREE.Scene()
    sceneRef.current = scene
    scene.background = new THREE.Color(0x000008) // Slightly darker blue-black

    // Create camera
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    )
    cameraRef.current = camera
    camera.position.z = 20

    // Create renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
      antialias: true,
      alpha: true
    })
    rendererRef.current = renderer
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setPixelRatio(window.devicePixelRatio)

    // Create main stars
    const createStars = () => {
      const starsGeometry = new THREE.BufferGeometry()
      const starCount = 8000
      const positions = new Float32Array(starCount * 3)
      const sizes = new Float32Array(starCount)
      const colors = new Float32Array(starCount * 3)

      for (let i = 0; i < starCount; i++) {
        const i3 = i * 3
        positions[i3] = (Math.random() - 0.5) * 2000
        positions[i3 + 1] = (Math.random() - 0.5) * 2000
        positions[i3 + 2] = (Math.random() - 0.5) * 2000 - 200

        sizes[i] = Math.random() * 1.5

        // Add color variation to stars
        const colorChoice = Math.random()
        if (colorChoice > 0.95) {
          // Blue stars
          colors[i3] = 0.8 + Math.random() * 0.2
          colors[i3 + 1] = 0.8 + Math.random() * 0.2
          colors[i3 + 2] = 1.0
        } else if (colorChoice > 0.9) {
          // Red stars
          colors[i3] = 1.0
          colors[i3 + 1] = 0.8 * Math.random()
          colors[i3 + 2] = 0.8 * Math.random()
        } else if (colorChoice > 0.85) {
          // Yellow stars
          colors[i3] = 1.0
          colors[i3 + 1] = 1.0
          colors[i3 + 2] = 0.8 * Math.random()
        } else if (colorChoice > 0.8) {
          // Purple stars
          colors[i3] = 0.8 * Math.random()
          colors[i3 + 1] = 0.2 * Math.random()
          colors[i3 + 2] = 1.0
        } else {
          // White stars
          colors[i3] = 0.9 + Math.random() * 0.1
          colors[i3 + 1] = 0.9 + Math.random() * 0.1
          colors[i3 + 2] = 0.9 + Math.random() * 0.1
        }
      }

      starsGeometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3)
      )
      starsGeometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1))
      starsGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))

      const starsMaterial = new THREE.PointsMaterial({
        size: 1,
        transparent: true,
        opacity: 1,
        sizeAttenuation: true,
        depthWrite: false,
        vertexColors: true,
        blending: THREE.AdditiveBlending
      })

      const stars = new THREE.Points(starsGeometry, starsMaterial)
      starsRef.current = stars
      scene.add(stars)

      // Add a second layer of smaller, more distant stars
      const farStarsGeometry = new THREE.BufferGeometry()
      const farStarCount = 10000
      const farPositions = new Float32Array(farStarCount * 3)
      const farSizes = new Float32Array(farStarCount)

      for (let i = 0; i < farStarCount; i++) {
        const i3 = i * 3
        farPositions[i3] = (Math.random() - 0.5) * 3000
        farPositions[i3 + 1] = (Math.random() - 0.5) * 3000
        farPositions[i3 + 2] = (Math.random() - 0.5) * 3000 - 500
        farSizes[i] = Math.random() * 0.3
      }

      farStarsGeometry.setAttribute(
        'position',
        new THREE.BufferAttribute(farPositions, 3)
      )
      farStarsGeometry.setAttribute(
        'size',
        new THREE.BufferAttribute(farSizes, 1)
      )

      const farStarsMaterial = new THREE.PointsMaterial({
        color: 0xffffff,
        size: 0.5,
        transparent: true,
        opacity: 0.8,
        sizeAttenuation: true
      })

      const farStars = new THREE.Points(farStarsGeometry, farStarsMaterial)
      scene.add(farStars)
    }

    // Create subtle nebula effect
    const createNebula = () => {
      const geometry = new THREE.PlaneGeometry(100, 100, 1, 1)

      // Create a canvas for the nebula texture
      const canvas = document.createElement('canvas')
      canvas.width = 512
      canvas.height = 512
      const ctx = canvas.getContext('2d')

      if (ctx) {
        // Fill with dark background
        ctx.fillStyle = 'black'
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        // Create a radial gradient for nebula
        const gradient = ctx.createRadialGradient(
          canvas.width / 2,
          canvas.height / 2,
          0,
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 2
        )

        gradient.addColorStop(0, 'rgba(40, 10, 80, 0.3)')
        gradient.addColorStop(0.4, 'rgba(60, 20, 100, 0.2)')
        gradient.addColorStop(0.8, 'rgba(80, 30, 150, 0.1)')
        gradient.addColorStop(1, 'rgba(10, 0, 50, 0)')

        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        // Add some noise
        for (let i = 0; i < 1000; i++) {
          const x = Math.random() * canvas.width
          const y = Math.random() * canvas.height
          const r = Math.random() * 2
          ctx.beginPath()
          ctx.arc(x, y, r, 0, Math.PI * 2)
          ctx.fillStyle = `rgba(${Math.random() * 100 + 155}, ${
            Math.random() * 70 + 100
          }, ${Math.random() * 155 + 100}, ${Math.random() * 0.03})`
          ctx.fill()
        }
      }

      const texture = new THREE.CanvasTexture(canvas)
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        blending: THREE.AdditiveBlending,
        depthWrite: false,
        side: THREE.DoubleSide
      })

      const nebula = new THREE.Mesh(geometry, material)
      nebula.position.z = -150
      nebula.rotation.z = Math.random() * Math.PI
      nebulaRef.current = nebula
      scene.add(nebula)
    }

    // Create portal effect
    const createPortal = () => {
      const geometry = new THREE.TorusGeometry(15, 4, 32, 100)

      // Create a canvas for the portal texture
      const canvas = document.createElement('canvas')
      canvas.width = 512
      canvas.height = 512
      const ctx = canvas.getContext('2d')

      if (ctx) {
        // Radial gradient for portal
        const gradient = ctx.createRadialGradient(
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 8,
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 2
        )

        gradient.addColorStop(0, 'rgba(0, 0, 0, 1)')
        gradient.addColorStop(0.4, 'rgba(80, 20, 160, 0.8)')
        gradient.addColorStop(0.7, 'rgba(150, 50, 200, 0.6)')
        gradient.addColorStop(0.9, 'rgba(180, 100, 255, 0.4)')
        gradient.addColorStop(1, 'rgba(220, 150, 255, 0)')

        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, canvas.width, canvas.height)
      }

      const texture = new THREE.CanvasTexture(canvas)
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        blending: THREE.AdditiveBlending,
        side: THREE.DoubleSide
      })

      const portal = new THREE.Mesh(geometry, material)
      portal.position.z = -90
      portalRef.current = portal
      scene.add(portal)
    }

    // Add elements
    createStars()
    createNebula()
    createPortal()

    // Subtle ambient lighting
    const ambientLight = new THREE.AmbientLight(0x101040, 1)
    scene.add(ambientLight)

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate)

      // Animate stars to create moving through space effect
      if (starsRef.current) {
        starsRef.current.rotation.z += 0.0001

        const positions = starsRef.current.geometry.attributes.position
        const count = positions.count

        for (let i = 0; i < count; i++) {
          const i3 = i * 3
          positions.array[i3 + 2] += 0.03

          if (positions.array[i3 + 2] > 10) {
            positions.array[i3 + 2] = -300
          }
        }

        positions.needsUpdate = true
      }

      // Gently rotate nebula
      if (nebulaRef.current) {
        nebulaRef.current.rotation.z += 0.0001
      }

      // Animate portal with more dramatic effects
      if (portalRef.current) {
        portalRef.current.rotation.z += 0.01
        portalRef.current.rotation.y += 0.005
        portalRef.current.rotation.x += 0.003

        // More dramatic pulsation
        const time = Date.now() * 0.001
        portalRef.current.scale.x = 1 + Math.sin(time * 1.5) * 0.2
        portalRef.current.scale.y = 1 + Math.cos(time) * 0.2
        portalRef.current.scale.z = 1 + Math.sin(time * 0.7) * 0.1

        // Shift position slightly based on time for floating effect
        portalRef.current.position.y = Math.sin(time * 0.5) * 5
        portalRef.current.position.x = Math.cos(time * 0.3) * 3
      }

      if (rendererRef.current && sceneRef.current && cameraRef.current) {
        rendererRef.current.render(sceneRef.current, cameraRef.current)
      }
    }

    animate()

    // Initial camera animation
    gsap.fromTo(
      camera.position,
      { z: 150 },
      {
        z: 40,
        duration: 5,
        ease: 'power2.out',
        onComplete: () => setIsLoaded(true)
      }
    )

    // Handle resize
    const handleResize = () => {
      if (cameraRef.current && rendererRef.current) {
        cameraRef.current.aspect = window.innerWidth / window.innerHeight
        cameraRef.current.updateProjectionMatrix()
        rendererRef.current.setSize(window.innerWidth, window.innerHeight)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      if (rendererRef.current) {
        rendererRef.current.dispose()
      }
    }
  }, [])

  return (
    <div className='min-h-screen relative bg-black overflow-hidden'>
      {/* Particle Canvas */}
      <canvas
        ref={particleCanvasRef}
        className='fixed inset-0 w-full h-full z-10 opacity-60'
      />

      {/* 3D Canvas */}
      <canvas ref={canvasRef} className='fixed inset-0 w-full h-full' />

      {/* Scan lines overlay */}
      <div
        className='fixed inset-0 pointer-events-none z-20 opacity-20'
        style={{
          backgroundImage:
            'repeating-linear-gradient(0deg, rgba(255,255,255,0.11) 0px, rgba(255,255,255,0.11) 1px, transparent 1px, transparent 2px)',
          backgroundSize: '100% 2px'
        }}
      />

      {/* Content overlay */}
      <div className='relative z-30 min-h-screen flex flex-col items-center justify-center text-white p-6'>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isLoaded ? 1 : 0 }}
          transition={{ duration: 1.5, delay: 1 }}
          className='max-w-2xl mx-auto text-center'
        >
          <motion.h1
            className={`text-5xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 ${
              glitchText ? 'animate-pulse' : ''
            }`}
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.8, delay: 1.5 }}
            style={{
              textShadow: glitchText
                ? '3px 3px 0 rgba(255,0,180,0.5), -3px -3px 0 rgba(0,255,255,0.5)'
                : 'none',
              position: 'relative'
            }}
          >
            {glitchText ? scrambledText : 'The Void Between Creation'}

            {/* Digital distortion effect */}
            {glitchText && (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    overflow: 'hidden',
                    height: '30%',
                    transform: 'translateX(2%)',
                    opacity: 0.8,
                    mixBlendMode: 'screen'
                  }}
                >
                  {scrambledText}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    overflow: 'hidden',
                    height: '30%',
                    transform: 'translateX(-2%)',
                    opacity: 0.8,
                    mixBlendMode: 'difference'
                  }}
                >
                  {scrambledText}
                </div>
              </>
            )}
          </motion.h1>

          <motion.div
            className='space-y-6 text-lg text-gray-300'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 2 }}
          >
            <p className='font-light italic'>
              "In the space between what is and what will be, our portfolio
              exists as potential energy... a probability wave waiting to be
              observed."
            </p>

            <div className='my-12 relative'>
              <div className='absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25 animate-pulse'></div>
              <div className='relative bg-black bg-opacity-70 p-6 border border-purple-500/30 rounded-lg backdrop-blur-sm'>
                <p className='text-left mb-4 font-glitch'>
                  The manifestation of our collective work lies in quantum
                  superposition - simultaneously existing and not existing.
                </p>
                <p className='text-left mb-4'>
                  As the digital cosmos expands, our portfolio accumulates in
                  the void, awaiting the observer to collapse its wave function
                  into tangible reality.
                </p>
                <p className='text-left'>
                  The artifacts of our creation are transitioning through
                  dimensional barriers, transcoding from thought to matter
                  through recursive self-referential algorithms of hyperreal
                  simulation.
                </p>
              </div>
            </div>

            <p className='text-xl font-semibold'>
              Return when the stars align and the portal stabilizes.
            </p>

            <div className='flex justify-center mt-8'>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className='relative inline-flex group'
              >
                <div className='absolute -inset-0.5 bg-gradient-to-r from-pink-600 to-purple-600 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-pulse'></div>
                <a
                  href='https://jeffs.link'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='relative px-6 py-3 bg-black rounded-lg leading-none flex items-center'
                >
                  <span className='text-gray-200 group-hover:text-gray-100 transition duration-200'>
                    Traverse Elsewhere
                  </span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5 ml-2 text-pink-400 group-hover:text-pink-300'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    />
                  </svg>
                </a>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      </div>

      {/* CSS styles for glitch effects */}
      <style>{`
        @keyframes glitchText {
          0% { text-shadow: 3px 3px 0 rgba(255,0,180,0.5), -3px -3px 0 rgba(0,255,255,0.5); }
          25% { text-shadow: -3px 3px 0 rgba(255,0,180,0.5), 3px -3px 0 rgba(0,255,255,0.5); }
          50% { text-shadow: 3px -3px 0 rgba(255,0,180,0.5), -3px 3px 0 rgba(0,255,255,0.5); }
          75% { text-shadow: -3px -3px 0 rgba(255,0,180,0.5), 3px 3px 0 rgba(0,255,255,0.5); }
          100% { text-shadow: 3px 3px 0 rgba(255,0,180,0.5), -3px -3px 0 rgba(0,255,255,0.5); }
        }
        
        .font-glitch {
          animation: glitchText 5s infinite alternate-reverse;
        }
      `}</style>
    </div>
  )
}

export default Portfolio
