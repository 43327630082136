import React, { useEffect, useState } from 'react'
import { ProjectLayout } from '../components/layouts'
import { ProjectList } from '../components/projects/ProjectList'
import { Project } from '../types/project'
import { projectService } from '../services/projectService'

const Projects: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const allProjects = await projectService.listProjects()
        setProjects(allProjects)
      } catch (err) {
        console.error('Error fetching project data:', err)
        setError('Failed to load projects')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div className="text-red-500">{error}</div>
  }

  return (
    <ProjectLayout>
      <div className="w-full overflow-x-hidden">
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-2">
            Projects
          </h1>
          <p className="text-gray-600 dark:text-gray-300">
            Browse my portfolio of development projects including web
            applications, libraries, and other technical work.
          </p>
        </div>
        {/* Project List Display */}
        <ProjectList projects={projects} />
      </div>
    </ProjectLayout>
  )
}

export default Projects
