import React from 'react'
import { motion } from 'framer-motion'

const Overview: React.FC = () => {
  return (
    <div className='container mx-auto px-4 sm:px-6 lg:px-8 py-6'>
      {/* Dynamic Hero Header Section */}
      <motion.section
        className='relative overflow-hidden bg-gradient-to-br from-gray-900 via-[#1e293b] to-[#0f172a] rounded-xl shadow-2xl mb-16'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        {/* Background animated elements */}
        <div className='absolute inset-0 overflow-hidden'>
          <motion.div
            className='absolute -top-24 -left-24 w-96 h-96 bg-[#52babb]/20 rounded-full blur-3xl'
            animate={{
              x: [0, 40, 0],
              y: [0, 30, 0],
              scale: [1, 1.2, 1]
            }}
            transition={{
              repeat: Infinity,
              duration: 15,
              ease: 'easeInOut'
            }}
          />
          <motion.div
            className='absolute top-1/2 -right-32 w-80 h-80 bg-indigo-500/20 rounded-full blur-3xl'
            animate={{
              x: [0, -30, 0],
              scale: [1, 1.3, 1]
            }}
            transition={{
              repeat: Infinity,
              duration: 20,
              ease: 'easeInOut'
            }}
          />
          <motion.div
            className='absolute bottom-0 left-1/3 w-64 h-64 bg-blue-500/20 rounded-full blur-3xl'
            animate={{
              y: [0, -40, 0],
              scale: [1, 1.2, 1]
            }}
            transition={{
              repeat: Infinity,
              duration: 18,
              ease: 'easeInOut'
            }}
          />
        </div>

        {/* Content */}
        <div className='relative z-10 py-20 px-8 md:py-28 md:px-16'>
          <div className='max-w-4xl mx-auto text-center'>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <h1 className='text-3xl md:text-5xl lg:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-[#52babb] via-[#4ECDC4] to-[#45B7D1]'>
                Web Solutions <br className='hidden md:block' />
                <span className='text-white'>Built for Impact</span>
              </h1>
            </motion.div>

            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className='text-gray-300 text-lg md:text-xl mb-8 max-w-2xl mx-auto'
            >
              Using technology to empower people and transform businesses.
            </motion.p>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.6 }}
              className='flex flex-col sm:flex-row gap-4 justify-center'
            >
              <a
                href='#services'
                className='px-8 py-3 bg-[#52babb] hover:bg-[#52babb]/90 text-white rounded-lg font-medium transition-all transform hover:scale-105'
              >
                Explore Services
              </a>
              <a
                href='#contact'
                className='px-8 py-3 bg-transparent hover:bg-white/10 border border-white/30 text-white rounded-lg font-medium transition-all transform hover:scale-105'
              >
                Get in Touch
              </a>
            </motion.div>
          </div>
        </div>

        {/* Code element decorations */}
        <div className='absolute bottom-8 left-8 opacity-30 hidden lg:block'>
          <pre className='text-[#52babb] text-xs'>
            {`{
  "@context": "https://schema.org",
  "@type": "WebApplication",
  "codeRepository": "github.com",
  "programmingLanguage": "TypeScript"
}`}
          </pre>
        </div>
        <div className='absolute top-8 right-8 opacity-30 hidden lg:block'>
          <pre className='text-[#52babb] text-xs'>
            {`/* Main Styles */
:root {
  --primary: #52babb;
  --secondary: #4ECDC4;
  --dark: #1e293b;
  --light: #f8fafc;
}

.hero {
  background: linear-gradient↵
  (to right, var(--dark), #0f172a);
  height: 100vh;
  display: flex;
  align-items: center;
}`}
          </pre>
        </div>
      </motion.section>

      {/* Our Services Section with ID for anchor link */}
      <motion.section
        id='services'
        className='mb-16'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        <h2 className='text-2xl font-bold mb-6 text-center'>Our Services</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow'>
            <div className='flex items-center mb-4'>
              <i className='fas fa-laptop-code text-2xl text-[#52babb] mr-3'></i>
              <h3 className='text-lg font-bold'>Custom Web Development</h3>
            </div>
            <p className='text-gray-600 dark:text-gray-300'>
              Modern, responsive websites and web applications built with the
              latest technologies. Focused on performance, user experience, and
              conversion optimization.
            </p>
          </div>
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow'>
            <div className='flex items-center mb-4'>
              <i className='fas fa-chart-line text-2xl text-[#52babb] mr-3'></i>
              <h3 className='text-lg font-bold'>Analytics & Data Solutions</h3>
            </div>
            <p className='text-gray-600 dark:text-gray-300'>
              Comprehensive data tracking, analysis, and visualization services.
              Turn your data into actionable insights and drive informed
              business decisions.
            </p>
          </div>
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow'>
            <div className='flex items-center mb-4'>
              <i className='fas fa-search text-2xl text-[#52babb] mr-3'></i>
              <h3 className='text-lg font-bold'>SEO & Digital Marketing</h3>
            </div>
            <p className='text-gray-600 dark:text-gray-300'>
              Improve your online visibility and reach your target audience.
              Technical SEO, content strategy, and performance optimization
              services.
            </p>
          </div>
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow'>
            <div className='flex items-center mb-4'>
              <i className='fab fa-aws text-2xl text-[#52babb] mr-3'></i>
              <h3 className='text-lg font-bold'>Cloud Architecture</h3>
            </div>
            <p className='text-gray-600 dark:text-gray-300'>
              Scalable, reliable cloud solutions built on AWS. Microservices,
              serverless architecture, and cloud migration services.
            </p>
          </div>
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow'>
            <div className='flex items-center mb-4'>
              <i className='fas fa-cogs text-2xl text-[#52babb] mr-3'></i>
              <h3 className='text-lg font-bold'>CMS & E-Commerce</h3>
            </div>
            <p className='text-gray-600 dark:text-gray-300'>
              Custom WordPress solutions, e-commerce platforms, and content
              management systems tailored to your business needs.
            </p>
          </div>
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow'>
            <div className='flex items-center mb-4'>
              <i className='fas fa-rocket text-2xl text-[#52babb] mr-3'></i>
              <h3 className='text-lg font-bold'>Technical Consulting</h3>
            </div>
            <p className='text-gray-600 dark:text-gray-300'>
              Expert guidance on technology strategy, implementation, and
              optimization. Let us help you navigate the digital landscape.
            </p>
          </div>
        </div>
      </motion.section>

      {/* Technical Stack Section */}
      <motion.section
        className='mb-16'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <h2 className='text-2xl font-bold mb-6 text-center'>
          Our Technical Stack
        </h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
          {/* Frontend */}
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-4'>
            <h3 className='text-lg font-bold mb-3 text-white'>Frontend</h3>
            <div className='flex flex-wrap gap-2'>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#52babb] hover:text-white'>
                React & Next.js
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#52babb] hover:text-white'>
                TypeScript
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#52babb] hover:text-white'>
                TailwindCSS
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#52babb] hover:text-white'>
                GSAP
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#52babb] hover:text-white'>
                WordPress
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#52babb] hover:text-white'>
                Figma
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#52babb] hover:text-white'>
                Adobe Creative Suite
              </span>
            </div>
          </div>
          {/* Backend */}
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-4'>
            <h3 className='text-lg font-bold mb-3 text-white'>Backend</h3>
            <div className='flex flex-wrap gap-2'>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#FF6B6B] hover:text-white'>
                Node.js
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#FF6B6B] hover:text-white'>
                Express
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#FF6B6B] hover:text-white'>
                MongoDB
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#FF6B6B] hover:text-white'>
                Python
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#FF6B6B] hover:text-white'>
                Java
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#FF6B6B] hover:text-white'>
                SQL
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#FF6B6B] hover:text-white'>
                Graph DB
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#FF6B6B] hover:text-white'>
                NoSQL
              </span>
            </div>
          </div>
          {/* Cloud & DevOps */}
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-4'>
            <h3 className='text-lg font-bold mb-3 text-white'>
              Cloud & DevOps
            </h3>
            <div className='flex flex-wrap gap-2'>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#4ECDC4] hover:text-white'>
                AWS Services
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#4ECDC4] hover:text-white'>
                Microservices
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#4ECDC4] hover:text-white'>
                Docker
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#4ECDC4] hover:text-white'>
                CI/CD
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#4ECDC4] hover:text-white'>
                Git
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#4ECDC4] hover:text-white'>
                Linux
              </span>
            </div>
          </div>
          {/* Analytics & Marketing */}
          <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-4'>
            <h3 className='text-lg font-bold mb-3 text-white'>
              Analytics & Marketing
            </h3>
            <div className='flex flex-wrap gap-2'>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#45B7D1] hover:text-white'>
                Google Analytics 4
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#45B7D1] hover:text-white'>
                SEO Optimization
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#45B7D1] hover:text-white'>
                Data Studio
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#45B7D1] hover:text-white'>
                Tag Management
              </span>
              <span className='px-3 py-1 bg-[#52babb]/10 dark:bg-[#52babb]/20 text-[#52babb] rounded-full text-sm transition-all duration-300 hover:bg-[#45B7D1] hover:text-white'>
                Marketing Automation
              </span>
            </div>
          </div>
        </div>
      </motion.section>

      {/* Strengths & Strategies Section - Replacing Featured Projects */}
      <motion.section
        className='mb-16'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        id='strengths'
      >
        <div className='flex flex-col items-center mb-10'>
          <motion.h2
            className='text-3xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#52babb] to-indigo-500 mb-4'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Strengths & Strategies
          </motion.h2>
          <motion.div
            className='h-1 w-24 bg-gradient-to-r from-[#52babb] to-indigo-500 rounded-full'
            initial={{ width: 0 }}
            animate={{ width: 96 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          />
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
          {/* Strength 1 - Strategic Thinking */}
          <motion.div
            className='group relative'
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            whileHover={{ y: -5 }}
          >
            <div className='absolute inset-0 bg-gradient-to-r from-blue-500 via-cyan-500 to-teal-500 rounded-xl blur-sm opacity-75 group-hover:opacity-100 transition duration-300'></div>
            <div className='relative h-full bg-gray-900 border border-gray-800 rounded-xl p-6 overflow-hidden'>
              {/* Icon Background - Animated */}
              <div className='absolute right-0 top-0 opacity-5'>
                <motion.div
                  animate={{
                    rotate: [0, 5, 0, -5, 0],
                    y: [0, -10, 0, 10, 0]
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 20,
                    ease: 'linear'
                  }}
                  className='text-[180px] font-bold text-white'
                >
                  <i className='fas fa-brain'></i>
                </motion.div>
              </div>

              <div className='relative z-10'>
                <div className='text-sm font-medium text-cyan-500 mb-1 uppercase tracking-wider'>
                  Problem Solving
                </div>
                <h3 className='text-xl font-bold text-white mb-3 group-hover:text-cyan-300 transition duration-300'>
                  Strategic Thinking
                </h3>

                <div className='h-[1px] w-full bg-gradient-to-r from-cyan-500 to-transparent mb-4 opacity-30'></div>

                <div className='text-gray-300 text-sm mb-4'>
                  <p>
                    We approach every project with a strategic mindset,
                    identifying core business challenges and developing targeted
                    solutions that align with long-term objectives.
                  </p>
                </div>

                <div className='mb-6 flex flex-wrap gap-2'>
                  <span className='px-2 py-1 text-xs rounded bg-cyan-500/10 text-cyan-400 border border-cyan-500/20'>
                    Business Analysis
                  </span>
                  <span className='px-2 py-1 text-xs rounded bg-cyan-500/10 text-cyan-400 border border-cyan-500/20'>
                    UX Strategy
                  </span>
                  <span className='px-2 py-1 text-xs rounded bg-cyan-500/10 text-cyan-400 border border-cyan-500/20'>
                    Systems Thinking
                  </span>
                </div>

                <div className='flex items-center justify-between mt-auto'>
                  <div className='text-cyan-400 text-xs'>
                    <span className='inline-block mr-2'>
                      <i className='fas fa-check-circle'></i>
                    </span>
                    Solutions that drive growth
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Strength 2 - Data-Driven Approach */}
          <motion.div
            className='group relative'
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            whileHover={{ y: -5 }}
          >
            <div className='absolute inset-0 bg-gradient-to-r from-purple-500 via-violet-500 to-indigo-500 rounded-xl blur-sm opacity-75 group-hover:opacity-100 transition duration-300'></div>
            <div className='relative h-full bg-gray-900 border border-gray-800 rounded-xl p-6 overflow-hidden'>
              {/* Icon Background - Animated */}
              <div className='absolute right-0 top-0 opacity-5'>
                <motion.div
                  animate={{
                    rotate: [0, 5, 0, -5, 0],
                    y: [0, -10, 0, 10, 0]
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 20,
                    ease: 'linear'
                  }}
                  className='text-[180px] font-bold text-white'
                >
                  <i className='fas fa-chart-line'></i>
                </motion.div>
              </div>

              <div className='relative z-10'>
                <div className='text-sm font-medium text-violet-500 mb-1 uppercase tracking-wider'>
                  Analytics Focus
                </div>
                <h3 className='text-xl font-bold text-white mb-3 group-hover:text-violet-300 transition duration-300'>
                  Data-Driven Approach
                </h3>

                <div className='h-[1px] w-full bg-gradient-to-r from-violet-500 to-transparent mb-4 opacity-30'></div>

                <div className='text-gray-300 text-sm mb-4'>
                  <p>
                    We leverage data at every stage of development—from initial
                    research to post-launch optimization—ensuring decisions are
                    backed by insights rather than assumptions.
                  </p>
                </div>

                <div className='mb-6 flex flex-wrap gap-2'>
                  <span className='px-2 py-1 text-xs rounded bg-violet-500/10 text-violet-400 border border-violet-500/20'>
                    Analytics
                  </span>
                  <span className='px-2 py-1 text-xs rounded bg-violet-500/10 text-violet-400 border border-violet-500/20'>
                    User Research
                  </span>
                  <span className='px-2 py-1 text-xs rounded bg-violet-500/10 text-violet-400 border border-violet-500/20'>
                    Performance Testing
                  </span>
                </div>

                <div className='flex items-center justify-between mt-auto'>
                  <div className='text-violet-400 text-xs'>
                    <span className='inline-block mr-2'>
                      <i className='fas fa-check-circle'></i>
                    </span>
                    Measurable business impact
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Strength 3 - Technical Adaptability */}
          <motion.div
            className='group relative'
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            whileHover={{ y: -5 }}
          >
            <div className='absolute inset-0 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 rounded-xl blur-sm opacity-75 group-hover:opacity-100 transition duration-300'></div>
            <div className='relative h-full bg-gray-900 border border-gray-800 rounded-xl p-6 overflow-hidden'>
              {/* Icon Background - Animated */}
              <div className='absolute right-0 top-0 opacity-5'>
                <motion.div
                  animate={{
                    rotate: [0, 5, 0, -5, 0],
                    y: [0, -10, 0, 10, 0]
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 20,
                    ease: 'linear'
                  }}
                  className='text-[180px] font-bold text-white'
                >
                  <i className='fas fa-code-branch'></i>
                </motion.div>
              </div>

              <div className='relative z-10'>
                <div className='text-sm font-medium text-red-500 mb-1 uppercase tracking-wider'>
                  Versatility
                </div>
                <h3 className='text-xl font-bold text-white mb-3 group-hover:text-red-300 transition duration-300'>
                  Technical Adaptability
                </h3>

                <div className='h-[1px] w-full bg-gradient-to-r from-red-500 to-transparent mb-4 opacity-30'></div>

                <div className='text-gray-300 text-sm mb-4'>
                  <p>
                    Our technology-agnostic approach allows us to architect
                    solutions that leverage cutting-edge frameworks while
                    strategically migrating legacy systems. We excel at
                    preserving valuable business logic while transforming
                    outdated infrastructure into modern, scalable platforms.
                  </p>
                </div>

                <div className='mb-6 flex flex-wrap gap-2'>
                  <span className='px-2 py-1 text-xs rounded bg-red-500/10 text-red-400 border border-red-500/20'>
                    Full-Stack Development
                  </span>
                  <span className='px-2 py-1 text-xs rounded bg-red-500/10 text-red-400 border border-red-500/20'>
                    Modern Frameworks
                  </span>
                  <span className='px-2 py-1 text-xs rounded bg-red-500/10 text-red-400 border border-red-500/20'>
                    Legacy Migration
                  </span>
                </div>

                <div className='flex items-center justify-between mt-auto'>
                  <div className='text-red-400 text-xs'>
                    <span className='inline-block mr-2'>
                      <i className='fas fa-check-circle'></i>
                    </span>
                    Future-proof solutions that preserve business value
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        <motion.div
          className='flex justify-center mt-10'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          <motion.a
            href='/portfolio'
            className='px-6 py-3 rounded-lg bg-gray-800/50 border border-gray-700 text-white hover:bg-gray-700/50 transition-all duration-300 flex items-center space-x-2'
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
          >
            <span>View Portfolio</span>
            <i className='fas fa-arrow-right'></i>
          </motion.a>
        </motion.div>
      </motion.section>

      {/* Contact Section - New */}
      <motion.section
        className='mb-16'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <h2 className='text-2xl font-bold mb-6 text-center'>Get In Touch</h2>
        <div className='bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm rounded-lg shadow-md p-6'>
          <p className='text-center text-gray-600 dark:text-gray-300 mb-6'>
            Ready to elevate your digital presence? Let's discuss how JKJR
            Digital can help your business thrive.
          </p>
          <div className='flex justify-center space-x-4'>
            <a
              href='mailto:jeff@jkjrdigital.com'
              className='px-6 py-3 bg-[#52babb] text-white rounded-lg hover:bg-[#52babb]/80 transition-colors'
            >
              <i className='fas fa-envelope mr-2'></i>Email Us
            </a>
            <a
              href='/contact'
              className='px-6 py-3 bg-white dark:bg-gray-700 text-[#52babb] border border-[#52babb] rounded-lg hover:bg-[#52babb]/10 transition-colors'
            >
              <i className='fas fa-message mr-2'></i>Contact Form
            </a>
          </div>
        </div>
      </motion.section>
    </div>
  )
}

export default Overview
