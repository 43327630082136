import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { BaseLayout } from '../components/layout'

const ResourcesSidebar: React.FC = () => {
  return (
    <div className='pr-0 lg:pr-6'>
      <div className='space-y-6'>
        {/* Documentation Section */}
        <motion.div
          className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h3 className='text-lg font-bold mb-4'>Development Docs</h3>
          <ul className='space-y-3'>
            <li>
              <a
                href='https://reactjs.org/docs/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-primary'
              >
                <i className='fab fa-react w-5 text-primary'></i>
                <span>React Documentation</span>
              </a>
            </li>
            <li>
              <a
                href='https://www.typescriptlang.org/docs/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center text-gray-600 dark:text-gray-300 hover:text-[#3178C6] dark:hover:text-[#3178C6]'
              >
                <i className='fas fa-code w-5 text-[#3178C6]'></i>
                <span>TypeScript Handbook</span>
              </a>
            </li>
            <li>
              <a
                href='https://developer.mozilla.org/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center text-gray-600 dark:text-gray-300 hover:text-[#FF9900] dark:hover:text-[#FF9900]'
              >
                <i className='fab fa-html5 w-5 text-[#e34c26]'></i>
                <span>MDN Web Docs</span>
              </a>
            </li>
            <li>
              <a
                href='https://aws.amazon.com/appsync/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center text-gray-600 dark:text-gray-300 hover:text-[#FF9900] dark:hover:text-[#FF9900]'
              >
                <i className='fab fa-aws w-5 text-[#FF9900]'></i>
                <span>AWS AppSync</span>
              </a>
            </li>
            <li>
              <a
                href='https://wordpress.org/documentation/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center text-gray-600 dark:text-gray-300 hover:text-[#21759b] dark:hover:text-[#21759b]'
              >
                <i className='fab fa-wordpress w-5 text-[#21759b]'></i>
                <span>WordPress</span>
              </a>
            </li>
            <li>
              <a
                href='https://university.webflow.com/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center text-gray-600 dark:text-gray-300 hover:text-[#4353ff] dark:hover:text-[#4353ff]'
              >
                <i className='fas fa-globe w-5 text-[#4353ff]'></i>
                <span>Webflow</span>
              </a>
            </li>
          </ul>
        </motion.div>

        {/* Design Tools Section - HIDDEN */}
        {/* <motion.div
          className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <h3 className='text-lg font-bold mb-4'>Design Tools</h3>
          <div className='space-y-4'>
            <a
              href='https://figma.com'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#F24E1E] dark:hover:text-[#F24E1E]'
            >
              <div className='flex items-center'>
                <i className='fab fa-figma text-[#F24E1E] mr-2'></i>
                <span className='font-medium'>Figma</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                Collaborative design and prototyping tool
              </p>
            </a>
            <a
              href='https://tailwindcss.com/docs'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#38bdf8] dark:hover:text-[#38bdf8]'
            >
              <div className='flex items-center'>
                <i className='fab fa-css3 text-[#38bdf8] mr-2'></i>
                <span className='font-medium'>TailwindCSS</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                Utility-first CSS framework
              </p>
            </a>
          </div>
        </motion.div> */}

        {/* Web Development Resources section */}
        <motion.div
          className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h3 className='text-lg font-bold mb-4'>Web Development</h3>
          <div className='space-y-4'>
            <a
              href='https://developer.mozilla.org/en-US/docs/Web/JavaScript'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#F7DF1E] dark:hover:text-[#F7DF1E]'
            >
              <div className='flex items-center'>
                <i className='fab fa-js text-[#F7DF1E] mr-2'></i>
                <span className='font-medium'>JavaScript</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                Core web programming language
              </p>
            </a>
            <a
              href='https://developer.mozilla.org/en-US/docs/Web/HTML'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#E34F26] dark:hover:text-[#E34F26]'
            >
              <div className='flex items-center'>
                <i className='fab fa-html5 text-[#E34F26] mr-2'></i>
                <span className='font-medium'>HTML5</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                Markup language for web pages
              </p>
            </a>
            <a
              href='https://developer.mozilla.org/en-US/docs/Web/CSS'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#264DE4] dark:hover:text-[#264DE4]'
            >
              <div className='flex items-center'>
                <i className='fab fa-css3-alt text-[#264DE4] mr-2'></i>
                <span className='font-medium'>CSS3</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                Styling language for web pages
              </p>
            </a>
            <a
              href='https://nodejs.org/en/docs/'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#339933] dark:hover:text-[#339933]'
            >
              <div className='flex items-center'>
                <i className='fab fa-node-js text-[#339933] mr-2'></i>
                <span className='font-medium'>Node.js</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                JavaScript runtime for server-side development
              </p>
            </a>
            <a
              href='https://git-scm.com/docs'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#F05032] dark:hover:text-[#F05032]'
            >
              <div className='flex items-center'>
                <i className='fab fa-git-alt text-[#F05032] mr-2'></i>
                <span className='font-medium'>Git</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                Version control system
              </p>
            </a>
          </div>
        </motion.div>

        {/* Obsidian Resources - Only visible on larger screens (2K+) */}
        <motion.div
          className='hidden 2xl:block bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h3 className='text-lg font-bold mb-4'>Obsidian Resources</h3>
          <div className='space-y-4'>
            <a
              href='https://obsidian.md/'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#7E6AD7] dark:hover:text-[#7E6AD7]'
            >
              <div className='flex items-center'>
                <i className='fas fa-brain text-[#7E6AD7] mr-2'></i>
                <span className='font-medium'>Obsidian</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                Powerful knowledge base that works on top of a local folder of
                plain text Markdown files.
                <span className='text-xs text-green-600 dark:text-green-400 ml-2 font-medium'>
                  (Free)
                </span>
              </p>
            </a>
            <a
              href='https://obsidian.md/sync'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#7E6AD7] dark:hover:text-[#7E6AD7]'
            >
              <div className='flex items-center'>
                <i className='fas fa-sync text-[#7E6AD7] mr-2'></i>
                <span className='font-medium'>Obsidian Sync</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                End-to-end encrypted sync service for your Obsidian vault across
                multiple devices.
                <span className='text-xs text-blue-600 dark:text-blue-400 ml-2 font-medium'>
                  (Paid)
                </span>
              </p>
            </a>
            <a
              href='https://obsidian.md/publish'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#7E6AD7] dark:hover:text-[#7E6AD7]'
            >
              <div className='flex items-center'>
                <i className='fas fa-globe text-[#7E6AD7] mr-2'></i>
                <span className='font-medium'>Obsidian Publish</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                Publish your notes as a beautiful website with minimal setup and
                complete control.
                <span className='text-xs text-blue-600 dark:text-blue-400 ml-2 font-medium'>
                  (Paid)
                </span>
              </p>
            </a>
            <a
              href='https://github.com/SilentVoid13/Templater'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#7E6AD7] dark:hover:text-[#7E6AD7]'
            >
              <div className='flex items-center'>
                <i className='fas fa-file-code text-[#7E6AD7] mr-2'></i>
                <span className='font-medium'>Templater</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                Powerful template language with scripting capabilities for
                creating custom templates.
                <span className='text-xs text-green-600 dark:text-green-400 ml-2 font-medium'>
                  (Free)
                </span>
              </p>
            </a>
            <a
              href='https://github.com/logancyang/obsidian-copilot'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#7E6AD7] dark:hover:text-[#7E6AD7]'
            >
              <div className='flex items-center'>
                <i className='fas fa-robot text-[#7E6AD7] mr-2'></i>
                <span className='font-medium'>Obsidian Copilot</span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                AI assistant for Obsidian that helps with writing, research, and
                knowledge management.
                <span className='text-xs text-blue-600 dark:text-blue-400 ml-2 font-medium'>
                  (Paid)
                </span>
              </p>
            </a>
            <a
              href='https://www.notion.so/'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-gray-600 dark:text-gray-300 hover:text-[#000000] dark:hover:text-white'
            >
              <div className='flex items-center'>
                <i className='fas fa-sticky-note text-[#000000] dark:text-white mr-2'></i>
                <span className='font-medium'>
                  Notion{' '}
                  <span className='text-xs text-gray-500'>(Alternative)</span>
                </span>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1 ml-6'>
                All-in-one workspace for notes, tasks, wikis, and databases with
                collaborative features and extensive templates.
                <span className='text-xs text-purple-600 dark:text-purple-400 ml-2 font-medium'>
                  (Freemium)
                </span>
              </p>
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

// Right sidebar component with learning resources
const LearningSidebar: React.FC = () => {
  return (
    <div className='pl-0 lg:pl-6'>
      <div className='space-y-6 h-full flex flex-col justify-between'>
        {/* Learning Resources */}
        <motion.div
          className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h3 className='text-lg font-bold mb-4'>Learning Platforms</h3>
          <ul className='space-y-3'>
            <li>
              <a
                href='https://www.freecodecamp.org/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center gap-3 text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-primary'
              >
                <div className='w-10 h-10 rounded-lg bg-primary/10 flex items-center justify-center'>
                  <i className='fas fa-code text-primary'></i>
                </div>
                <div>
                  <h4 className='text-sm font-medium'>freeCodeCamp</h4>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>
                    Free coding tutorials
                  </p>
                </div>
              </a>
            </li>
            <li>
              <a
                href='https://aws.amazon.com/getting-started/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center gap-3 text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-primary'
              >
                <div className='w-10 h-10 rounded-lg bg-[#FF9900]/10 flex items-center justify-center'>
                  <i className='fab fa-aws text-[#FF9900]'></i>
                </div>
                <div>
                  <h4 className='text-sm font-medium'>AWS Resources</h4>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>
                    Cloud documentation
                  </p>
                </div>
              </a>
            </li>
            <li>
              <a
                href='https://acloudguru.com/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center gap-3 text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-primary'
              >
                <div className='w-10 h-10 rounded-lg bg-[#02CCBA]/10 flex items-center justify-center'>
                  <i className='fas fa-cloud text-[#02CCBA]'></i>
                </div>
                <div>
                  <h4 className='text-sm font-medium'>A Cloud Guru</h4>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>
                    Cloud learning platform
                  </p>
                </div>
              </a>
            </li>
            <li>
              <a
                href='https://codepen.io/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center gap-3 text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-primary'
              >
                <div className='w-10 h-10 rounded-lg bg-[#0057ff]/10 flex items-center justify-center'>
                  <i className='fab fa-codepen text-[#0057ff]'></i>
                </div>
                <div>
                  <h4 className='text-sm font-medium'>CodePen</h4>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>
                    Code playground
                  </p>
                </div>
              </a>
            </li>
            <li>
              <a
                href='https://www.udemy.com/'
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center gap-3 text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-primary'
              >
                <div className='w-10 h-10 rounded-lg bg-[#a435f0]/10 flex items-center justify-center'>
                  <i className='fas fa-laptop-code text-[#a435f0]'></i>
                </div>
                <div>
                  <h4 className='text-sm font-medium'>Udemy</h4>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>
                    Online learning marketplace
                  </p>
                </div>
              </a>
            </li>
          </ul>
        </motion.div>

        {/* Marketing Resources */}
        <motion.div
          className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <h3 className='text-lg font-bold mb-4'>Marketing Tools</h3>
          <div className='space-y-4'>
            <a
              href='https://analytics.google.com/'
              target='_blank'
              rel='noopener noreferrer'
              className='block p-3 bg-gray-50/60 dark:bg-gray-800/60 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 transition-colors backdrop-blur-sm'
            >
              <div className='flex items-center justify-between mb-1'>
                <span className='font-medium'>Google Analytics</span>
                <i className='fas fa-chart-line text-[#E37400]'></i>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400'>
                Web analytics service for tracking
              </p>
            </a>
            <a
              href='https://www.spyfu.com/'
              target='_blank'
              rel='noopener noreferrer'
              className='block p-3 bg-gray-50/60 dark:bg-gray-800/60 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 transition-colors backdrop-blur-sm'
            >
              <div className='flex items-center justify-between mb-1'>
                <span className='font-medium'>SpyFu</span>
                <i className='fas fa-search-dollar text-[#21B15D]'></i>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400'>
                Competitor keyword research
              </p>
            </a>
            <a
              href='https://search.google.com/search-console'
              target='_blank'
              rel='noopener noreferrer'
              className='block p-3 bg-gray-50/60 dark:bg-gray-800/60 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 transition-colors backdrop-blur-sm'
            >
              <div className='flex items-center justify-between mb-1'>
                <span className='font-medium'>Search Console</span>
                <i className='fab fa-google text-[#1A73E8]'></i>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400'>
                Track search performance
              </p>
            </a>
            <a
              href='https://datastudio.google.com/'
              target='_blank'
              rel='noopener noreferrer'
              className='block p-3 bg-gray-50/60 dark:bg-gray-800/60 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 transition-colors backdrop-blur-sm'
            >
              <div className='flex items-center justify-between mb-1'>
                <span className='font-medium'>Data Studio</span>
                <i className='fas fa-chart-bar text-[#4285F4]'></i>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400'>
                Data visualization tool
              </p>
            </a>
            <a
              href='https://powermyanalytics.com/'
              target='_blank'
              rel='noopener noreferrer'
              className='block p-3 bg-gray-50/60 dark:bg-gray-800/60 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 transition-colors backdrop-blur-sm'
            >
              <div className='flex items-center justify-between mb-1'>
                <span className='font-medium'>Power My Analytics</span>
                <i className='fas fa-tachometer-alt text-[#FF6B00]'></i>
              </div>
              <p className='text-xs text-gray-500 dark:text-gray-400'>
                Data integration platform
              </p>
            </a>
          </div>
        </motion.div>

        {/* Resource Newsletter */}
        <motion.div
          className='bg-gradient-to-r from-primary/80 to-primary-dark/80 text-white rounded-lg shadow-md p-6 backdrop-blur-sm'
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h3 className='text-lg font-bold mb-3'>Weekly Resources</h3>
          <p className='text-sm mb-4 opacity-90'>
            Get weekly curated resources in your inbox.
          </p>
          <a
            href='/contact'
            className='block text-center w-full bg-white text-primary hover:bg-gray-50 px-4 py-2 rounded-lg font-medium transition-colors'
          >
            Subscribe
          </a>
        </motion.div>
      </div>
    </div>
  )
}

// Section header component
interface SectionHeaderProps {
  title: string
  subtitle: string
  id?: string
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  id
}) => {
  return (
    <motion.section
      id={id}
      className='flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6 border-b border-gray-200 dark:border-gray-700 pb-2'
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className='text-2xl font-bold flex items-center'>
        {title}
        <div className='h-1 w-8 bg-primary ml-3 rounded-full'></div>
      </h2>
      <p className='text-sm text-gray-600 dark:text-gray-300 mt-1 sm:mt-0 max-w-xl'>
        {subtitle}
      </p>
    </motion.section>
  )
}

const Resources: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState('aws')

  useEffect(() => {
    // Set page title
    document.title = 'Developer Resources | Jeff Knowles Jr'

    // Update meta tags
    const metaTags = [
      {
        name: 'description',
        content:
          'Resources I use and recommend for development work - tools for AWS, React, design and knowledge management. A personal collection built over years of practice.'
      },
      {
        property: 'og:title',
        content: 'Web Developer Resources | AWS, React & Design Tools'
      },
      {
        property: 'og:description',
        content:
          'My go-to development resources: AWS services, React libraries, design tools, and knowledge management with Obsidian. Tools that make my workflow smoother.'
      },
      {
        property: 'og:url',
        content: 'https://www.jkjrdev.com/resources'
      }
    ]

    // Remove existing meta tags
    document.querySelectorAll('meta').forEach((tag) => {
      if (
        tag.getAttribute('property')?.startsWith('og:') ||
        tag.getAttribute('name') === 'description'
      ) {
        tag.remove()
      }
    })

    // Add updated meta tags
    metaTags.forEach((tag) => {
      const meta = document.createElement('meta')
      const key = Object.keys(tag).find((k) => k !== 'content') as string
      meta.setAttribute(key, tag[key as keyof typeof tag] as string)
      meta.setAttribute('content', tag.content)
      document.head.appendChild(meta)
    })

    // Update canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]')
    if (!canonicalLink) {
      canonicalLink = document.createElement('link')
      canonicalLink.setAttribute('rel', 'canonical')
      document.head.appendChild(canonicalLink)
    }
    canonicalLink.setAttribute('href', 'https://www.jkjrdev.com/resources')
  }, [])

  // Function to handle tab changes
  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId)

    // Scroll to section
    const element = document.getElementById(tabId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  // New text-based tab component with icons
  const TabLink: React.FC<{
    id: string
    title: string
    icon: string
  }> = ({ id, title, icon }) => {
    const tabId = id.replace('-tab', '')

    return (
      <button
        onClick={() => handleTabChange(tabId)}
        className={`flex items-center gap-2 font-medium transition-colors ${
          activeTab === tabId
            ? 'text-white'
            : 'text-gray-400 hover:text-gray-200'
        }`}
      >
        <i
          className={`${icon} ${activeTab === tabId ? 'text-primary' : ''}`}
        ></i>
        {title}
        {activeTab === tabId && (
          <span className='h-1.5 w-1.5 rounded-full bg-primary ml-1'></span>
        )}
      </button>
    )
  }

  return (
    <BaseLayout hideFooter={true}>
      <div className='w-full px-4 sm:px-6 lg:px-8 py-8'>
        <div className='flex flex-col lg:flex-row lg:space-x-0 max-w-full'>
          {/* Left Resources Sidebar - Moved to bottom on mobile/tablet */}
          <div className='w-full lg:w-64 xl:w-72 shrink-0 lg:block order-3 md:order-3 lg:order-1 mb-8 lg:mb-0'>
            <div className='lg:sticky lg:top-8'>
              <ResourcesSidebar />
            </div>
          </div>

          {/* Main Content */}
          <main className='flex-1 px-0 lg:px-6 order-1 md:order-1 lg:order-2'>
            {/* Tailwind-focused hero section with more transparency - for larger screens */}
            <motion.div
              className='w-full -mt-8 mb-12 hidden md:block'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className='relative overflow-hidden rounded-xl backdrop-blur-xl bg-gradient-to-br from-gray-900/60 to-gray-800/60 border border-white/10'>
                {/* Decorative elements */}
                <div className='absolute inset-0'>
                  <div className='absolute inset-0 bg-gradient-to-br from-primary/10 to-purple-500/5'></div>
                  <div className='absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-primary/30 to-transparent'></div>
                  <div className='absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-purple-500/30 to-transparent'></div>

                  {/* Grid pattern */}
                  <div
                    className='absolute inset-0 opacity-5'
                    style={{
                      backgroundImage:
                        'linear-gradient(to right, #52babb 1px, transparent 1px), linear-gradient(to bottom, #52babb 1px, transparent 1px)',
                      backgroundSize: '40px 40px'
                    }}
                  ></div>

                  {/* Glow spots */}
                  <div className='absolute -top-24 -left-20 w-72 h-72 bg-primary/20 rounded-full blur-3xl opacity-20'></div>
                  <div className='absolute -bottom-24 -right-20 w-72 h-72 bg-purple-500/20 rounded-full blur-3xl opacity-20'></div>
                </div>

                <div className='relative py-16 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center'>
                  {/* Title with gradient text */}
                  <motion.h1
                    className='text-4xl sm:text-5xl font-extrabold text-center mb-4'
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <span className='bg-clip-text text-transparent bg-gradient-to-r from-primary via-primary-light to-teal-300'>
                      Developer Resources
                    </span>
                  </motion.h1>

                  <motion.p
                    className='text-gray-300 text-center max-w-2xl mb-10 text-lg'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                  >
                    A curated collection of found tools, documentation and
                    platforms for you and your team to build your next big
                    thing.
                  </motion.p>

                  {/* Tab Links */}
                  <motion.div
                    className='flex flex-wrap justify-center gap-6 z-10'
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <TabLink id='aws-tab' title='AWS Tools' icon='fab fa-aws' />
                    <TabLink
                      id='development-tab'
                      title='Dev Resources'
                      icon='fas fa-code'
                    />
                    <TabLink
                      id='design-tab'
                      title='Design'
                      icon='fas fa-palette'
                    />
                    <TabLink
                      id='obsidian-tab'
                      title='Obsidian'
                      icon='fas fa-brain'
                    />
                  </motion.div>

                  {/* Floating code element */}
                  <div className='hidden sm:block absolute bottom-10 right-10 opacity-20 rotate-2'>
                    <div className='text-xs font-mono text-gray-400 bg-black/10 p-3 rounded-md backdrop-blur-sm border border-gray-700/20'>
                      <span className='text-primary-light'>import</span>{' '}
                      <span className='text-purple-400'>
                        &#123; resources &#125;
                      </span>{' '}
                      <span className='text-primary-light'>from</span>{' '}
                      <span className='text-teal-300'>'@/developer-tools'</span>
                      ;
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Compact hero with better margin alignments - for smaller screens */}
            <motion.div
              className='w-full mt-0 mb-10 block md:hidden'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className='relative overflow-hidden rounded-xl backdrop-blur-xl bg-gradient-to-br from-gray-900/60 to-gray-800/60 border border-white/10'>
                <div className='absolute inset-0'>
                  <div className='absolute inset-0 bg-gradient-to-br from-primary/10 to-purple-500/5'></div>
                  <div className='absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-primary/30 to-transparent'></div>
                  <div className='absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-purple-500/30 to-transparent'></div>
                  <div
                    className='absolute inset-0 opacity-5'
                    style={{
                      backgroundImage:
                        'linear-gradient(to right, #52babb 1px, transparent 1px), linear-gradient(to bottom, #52babb 1px, transparent 1px)',
                      backgroundSize: '40px 40px'
                    }}
                  ></div>
                </div>

                <div className='relative py-8 sm:py-10 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center'>
                  <motion.h1
                    className='text-3xl font-extrabold text-center mb-3'
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <span className='bg-clip-text text-transparent bg-gradient-to-r from-primary via-primary-light to-teal-300'>
                      Developer Resources
                    </span>
                  </motion.h1>

                  <motion.p
                    className='text-gray-300 text-center max-w-2xl mb-6 text-base'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                  >
                    A curated collection of tools and documentation for modern
                    web development
                  </motion.p>

                  <motion.div
                    className='flex flex-wrap justify-center gap-6 z-10'
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <TabLink id='aws-tab' title='AWS Tools' icon='fab fa-aws' />
                    <TabLink
                      id='development-tab'
                      title='Dev Resources'
                      icon='fas fa-code'
                    />
                    <TabLink
                      id='design-tab'
                      title='Design'
                      icon='fas fa-palette'
                    />
                    <TabLink
                      id='obsidian-tab'
                      title='Obsidian'
                      icon='fas fa-brain'
                    />
                  </motion.div>
                </div>
              </div>
            </motion.div>

            {/* Main content sections controlled by tabs */}

            {/* AWS Technologies Section */}
            <div style={{ display: activeTab === 'aws' ? 'block' : 'none' }}>
              <SectionHeader
                id='aws'
                title='AWS Technologies'
                subtitle='Essential Amazon Web Services for cloud architecture and development'
              />

              <motion.div
                className='grid grid-cols-1 sm:grid-cols-2 gap-6 mb-12'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#00C7B7]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>AWS Lambda</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Serverless compute service that runs code in response to
                    events and automatically manages the underlying compute
                    resources.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/lambda/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#0080FF]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Amazon S3</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Object storage service offering industry-leading
                    scalability, data availability, security, and performance.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/s3/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#FF9900]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Amazon DynamoDB</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Fast and flexible NoSQL database service for single-digit
                    millisecond performance at any scale.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/dynamodb/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#9D5CFF]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>AWS API Gateway</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Create, publish, maintain, monitor, and secure APIs at any
                    scale for serverless applications.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/api-gateway/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#00A1C9]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>AWS CloudFormation</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Infrastructure as code service for modeling and provisioning
                    cloud application resources.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/cloudformation/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#25BE6A]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>AWS AppSync</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Managed GraphQL service for building scalable applications
                    with real-time data synchronization and offline programming
                    capabilities.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/appsync/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#2E73B7]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Amazon Route 53</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Scalable DNS and domain registration service with ultra-low
                    latency and high availability for routing users to
                    applications.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/route53/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#E31C3D]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Amazon CloudFront</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Content delivery network (CDN) service for securely
                    delivering data, videos, applications, and APIs globally
                    with low latency.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/cloudfront/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#FF9EB0]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>AWS Amplify</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Complete solution for building full-stack web and mobile
                    applications with simplified workflows for frontend and
                    backend development.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/amplify/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#1DC9B7]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Amazon Lightsail</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Simple virtual private servers with low, predictable pricing
                    and everything needed to build applications or websites.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/lightsail/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#FF9900]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Amazon Aurora RDS</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    MySQL and PostgreSQL-compatible relational database built
                    for the cloud, combining performance and availability with
                    simplicity.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/rds/aurora/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#D93218]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>AWS IAM</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Identity and Access Management service for securely
                    controlling access to AWS services and resources with
                    fine-grained permissions.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/iam/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#3B83F7]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Amazon SES</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Simple Email Service for sending marketing, notification,
                    and transactional emails with high deliverability and
                    flexible integration.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/ses/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#FF9633]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Amazon Cognito</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    User authentication, authorization, and user management for
                    web and mobile apps with support for social identity
                    providers.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/cognito/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm border-t-4 border-[#00C7B7]'>
                  <div className='text-[#FF9900] text-3xl mb-4'>
                    <i className='fab fa-aws'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Amazon EC2</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Elastic Compute Cloud provides secure and resizable virtual
                    servers in the cloud with complete control of the computing
                    environment.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://aws.amazon.com/ec2/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FF9900] hover:text-[#e68a00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#7B42BC] text-3xl mb-4'>
                    <i className='fas fa-cogs'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Terraform</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Infrastructure as code tool for building, changing, and
                    versioning infrastructure efficiently and safely across
                    cloud providers.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://www.terraform.io/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#7B42BC] hover:text-[#623696]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Development Resources Section */}
            <div
              style={{
                display: activeTab === 'development' ? 'block' : 'none'
              }}
            >
              <SectionHeader
                id='development'
                title='Development Resources'
                subtitle='Essential tools and documentation for web development'
              />

              <motion.div
                className='grid grid-cols-1 sm:grid-cols-2 gap-6 mb-12'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-primary text-3xl mb-4'>
                    <i className='fab fa-react'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>
                    React Documentation
                  </h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Official React documentation with guides and API reference
                    for building modern web applications.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://reactjs.org/docs/getting-started.html'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-primary hover:text-primary-dark'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#3178C6] text-3xl mb-4'>
                    <i className='fab fa-js-square'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>
                    TypeScript Handbook
                  </h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Comprehensive guide to TypeScript features and best
                    practices for type-safe applications.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://www.typescriptlang.org/docs/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#3178C6] hover:text-[#2a6cb0]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#e34c26] text-3xl mb-4'>
                    <i className='fab fa-html5'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>MDN Web Docs</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Comprehensive documentation for web standards, including
                    HTML, CSS, JavaScript, and Web APIs.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://developer.mozilla.org/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#e34c26] hover:text-[#c7391b]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#000000] text-3xl mb-4 dark:text-white'>
                    <i className='fab fa-github'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>GitHub</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Source code hosting and collaboration platform for version
                    control and project management.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://github.com'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#000000] dark:text-[#ffffff] hover:text-gray-600 dark:hover:text-gray-300'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#21759b] text-3xl mb-4'>
                    <i className='fab fa-wordpress'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>WordPress</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Popular CMS platform powering over 40% of the web with
                    extensive plugin ecosystem and customization options.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://wordpress.org/documentation/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#21759b] hover:text-[#1a5676]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#4353ff] text-3xl mb-4'>
                    <i className='fas fa-globe'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Webflow</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Visual web design platform that empowers designers to build
                    professional, custom websites without coding.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://university.webflow.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#4353ff] hover:text-[#3543cc]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#2496ED] text-3xl mb-4'>
                    <i className='fab fa-docker'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Docker</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Platform for developing, shipping, and running applications
                    in containers for consistent environments across development
                    and production.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://docs.docker.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#2496ED] hover:text-[#1a7bc8]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#24292F] text-3xl mb-4 dark:text-white'>
                    <i className='fab fa-github-alt'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>GitHub Actions</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    CI/CD platform integrated with GitHub that automates build,
                    test, and deployment workflows directly from your GitHub
                    repository.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://github.com/features/actions'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#24292F] dark:text-white hover:text-gray-600 dark:hover:text-gray-300'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#D24939] text-3xl mb-4'>
                    <i className='fab fa-jenkins'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Jenkins</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Open-source automation server that enables developers to
                    build, test, and deploy their software with extensive plugin
                    ecosystem.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://www.jenkins.io/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#D24939] hover:text-[#b03c2d]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#343434] text-3xl mb-4 dark:text-white'>
                    <i className='fab fa-circle-notch'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>CircleCI</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Cloud-based CI/CD platform designed to optimize the software
                    development process with faster builds and automated
                    testing.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://circleci.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#343434] dark:text-white hover:text-gray-600 dark:hover:text-gray-300'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Design Resources Section */}
            <div style={{ display: activeTab === 'design' ? 'block' : 'none' }}>
              <SectionHeader
                id='design'
                title='Design Resources'
                subtitle='Resources for UI/UX design and visual elements'
              />

              <motion.div
                className='grid grid-cols-1 sm:grid-cols-2 gap-6 mb-12'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#F24E1E] text-3xl mb-4'>
                    <i className='fab fa-figma'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Figma</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Collaborative design and prototyping tool for teams to
                    create, test, and ship designs.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://www.figma.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#F24E1E] hover:text-[#d43d11]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#38bdf8] text-3xl mb-4'>
                    <i className='fab fa-css3'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>TailwindCSS</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Utility-first CSS framework for rapid UI development with
                    pre-designed components.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://tailwindcss.com/docs'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#38bdf8] hover:text-[#0f9bd7]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#0057ff] text-3xl mb-4'>
                    <i className='fab fa-behance'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Behance</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Design inspiration and showcase platform for discovering
                    creative work and connecting with designers.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://www.behance.net/jr'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#1769ff] hover:text-blue-600'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#16a5a5] text-3xl mb-4'>
                    <i className='fas fa-images'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Unsplash</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Free high-quality stock photos for your projects and designs
                    without attribution.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://unsplash.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#16a5a5] hover:text-[#0f7e7e]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#2175B1] text-3xl mb-4'>
                    <i className='fas fa-palette'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>
                    Adobe Color (Kuler)
                  </h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Color theme creator with accessibility tools and harmony
                    rules for perfect color combinations.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://color.adobe.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#2175B1] hover:text-[#185785]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#FA0F00] text-3xl mb-4'>
                    <i className='fas fa-photo-video'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>
                    Adobe Creative Suite
                  </h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Collection of creative applications for graphic design,
                    video editing, web development, and photography.
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://www.adobe.com/creativecloud.html'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#FA0F00] hover:text-[#D80D00]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#00C4CC] text-3xl mb-4'>
                    <i className='fas fa-palette'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Canva</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Online design platform with drag-and-drop interface,
                    templates, and assets for creating everything from social
                    media graphics to presentations.
                    <span className='text-xs text-purple-600 dark:text-purple-400 ml-2 font-medium'>
                      (Freemium)
                    </span>
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://www.canva.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#00C4CC] hover:text-[#00a3a9]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Obsidian Resources Section */}
            <div
              style={{ display: activeTab === 'obsidian' ? 'block' : 'none' }}
            >
              <SectionHeader
                id='obsidian'
                title='Obsidian Resources'
                subtitle='Tools and plugins for knowledge management and note-taking'
              />

              <motion.div
                className='grid grid-cols-1 sm:grid-cols-2 gap-6 mb-12'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#7E6AD7] text-3xl mb-4'>
                    <i className='fas fa-brain'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Obsidian</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Powerful knowledge base that works on top of a local folder
                    of plain text Markdown files.
                    <span className='text-xs text-green-600 dark:text-green-400 ml-2 font-medium'>
                      (Free)
                    </span>
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://obsidian.md/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#7E6AD7] hover:text-[#6652b1]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#7E6AD7] text-3xl mb-4'>
                    <i className='fas fa-sync'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Obsidian Sync</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    End-to-end encrypted sync service for your Obsidian vault
                    across multiple devices.
                    <span className='text-xs text-blue-600 dark:text-blue-400 ml-2 font-medium'>
                      (Paid)
                    </span>
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://obsidian.md/sync'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#7E6AD7] hover:text-[#6652b1]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#7E6AD7] text-3xl mb-4'>
                    <i className='fas fa-globe'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Obsidian Publish</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Publish your notes as a beautiful website with minimal setup
                    and complete control.
                    <span className='text-xs text-blue-600 dark:text-blue-400 ml-2 font-medium'>
                      (Paid)
                    </span>
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://obsidian.md/publish'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#7E6AD7] hover:text-[#6652b1]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#7E6AD7] text-3xl mb-4'>
                    <i className='fas fa-file-code'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Templater</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    Powerful template language with scripting capabilities for
                    creating custom templates.
                    <span className='text-xs text-green-600 dark:text-green-400 ml-2 font-medium'>
                      (Free)
                    </span>
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://github.com/SilentVoid13/Templater'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#7E6AD7] hover:text-[#6652b1]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#7E6AD7] text-3xl mb-4'>
                    <i className='fas fa-robot'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>Obsidian Copilot</h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    AI assistant for Obsidian that helps with writing, research,
                    and knowledge management.
                    <span className='text-xs text-blue-600 dark:text-blue-400 ml-2 font-medium'>
                      (Paid)
                    </span>
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://github.com/logancyang/obsidian-copilot'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#7E6AD7] hover:text-[#6652b1]'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>

                <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
                  <div className='text-[#000000] text-3xl mb-4 dark:text-white'>
                    <i className='fas fa-sticky-note'></i>
                  </div>
                  <h3 className='text-lg font-bold mb-2'>
                    Notion{' '}
                    <span className='text-xs text-gray-500'>(Alternative)</span>
                  </h3>
                  <p className='text-gray-600 dark:text-gray-300'>
                    All-in-one workspace for notes, tasks, wikis, and databases
                    with collaborative features and extensive templates.
                    <span className='text-xs text-purple-600 dark:text-purple-400 ml-2 font-medium'>
                      (Freemium)
                    </span>
                  </p>
                  <div className='mt-4'>
                    <a
                      href='https://www.notion.so/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#000000] dark:text-[#ffffff] hover:text-gray-600 dark:hover:text-gray-300'
                    >
                      View Resource →
                    </a>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Newsletter Section - Always visible */}
            <SectionHeader
              title='Resource Newsletter'
              subtitle='Subscribe to receive weekly curated resources for developers and designers'
            />

            <motion.div
              className='bg-gradient-to-r from-primary/80 to-primary-dark/80 text-white rounded-lg shadow-md p-8 sm:p-12 mb-12 backdrop-blur-sm'
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <div className='max-w-4xl mx-auto text-center'>
                <h2 className='text-2xl sm:text-3xl font-bold mb-4 sm:mb-6'>
                  Stay Updated
                </h2>
                <p className='mb-6 sm:mb-10 text-base sm:text-lg'>
                  Get the latest resources, tools, and tutorials delivered
                  straight to your inbox every week.
                </p>
                <div className='flex flex-col sm:flex-row justify-center gap-4 sm:gap-6'>
                  <a
                    href='/contact'
                    className='px-6 sm:px-10 py-3 sm:py-4 bg-white text-primary hover:bg-gray-100 rounded-lg transition-colors duration-200 font-medium text-base sm:text-lg'
                  >
                    Subscribe Now
                  </a>
                  <a
                    href='/blog'
                    className='px-6 sm:px-10 py-3 sm:py-4 bg-transparent border-2 border-white text-white hover:bg-white hover:text-primary rounded-lg transition-colors duration-200 font-medium text-base sm:text-lg'
                  >
                    Read Blog
                  </a>
                </div>
              </div>
            </motion.div>
          </main>

          {/* Right Sidebar */}
          <div className='w-full lg:w-64 xl:w-72 shrink-0 order-2 md:order-2 lg:order-3 mt-8 lg:mt-0'>
            <div className='lg:sticky lg:top-8'>
              <LearningSidebar />
            </div>
          </div>
        </div>

        {/* On tablet, show a better sequence of content */}
        <div className='hidden md:block lg:hidden mt-12 border-t border-gray-200 dark:border-gray-700 pt-8'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
            <div className='bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm'>
              <h3 className='text-xl font-bold mb-4 text-primary flex items-center'>
                <i className='fas fa-compass mr-2'></i>
                Quick Access
              </h3>
              <div className='space-y-3'>
                <a
                  href='#aws'
                  className='flex items-center gap-2 p-2 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 text-gray-600 dark:text-gray-300 hover:text-primary transition-colors'
                >
                  <i className='fab fa-aws w-5 text-[#FF9900]'></i>
                  <span>AWS Technologies</span>
                </a>
                <a
                  href='#development'
                  className='flex items-center gap-2 p-2 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 text-gray-600 dark:text-gray-300 hover:text-primary transition-colors'
                >
                  <i className='fas fa-code w-5 text-primary'></i>
                  <span>Development Resources</span>
                </a>
                <a
                  href='#design'
                  className='flex items-center gap-2 p-2 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 text-gray-600 dark:text-gray-300 hover:text-primary transition-colors'
                >
                  <i className='fas fa-palette w-5 text-[#F24E1E]'></i>
                  <span>Design Resources</span>
                </a>
                <a
                  href='#obsidian'
                  className='flex items-center gap-2 p-2 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 text-gray-600 dark:text-gray-300 hover:text-primary transition-colors'
                >
                  <i className='fas fa-brain w-5 text-[#7E6AD7]'></i>
                  <span>Obsidian Resources</span>
                </a>
                <a
                  href='#learning'
                  className='flex items-center gap-2 p-2 rounded-md hover:bg-gray-100/60 dark:hover:bg-gray-700/60 text-gray-600 dark:text-gray-300 hover:text-primary transition-colors'
                >
                  <i className='fas fa-graduation-cap w-5 text-[#14bf96]'></i>
                  <span>Learning Resources</span>
                </a>
              </div>
            </div>

            <div className='bg-gradient-to-r from-primary/80 to-primary-dark/80 text-white rounded-lg shadow-md p-6 backdrop-blur-sm'>
              <h3 className='text-xl font-bold mb-4 flex items-center'>
                <i className='fas fa-envelope-open-text mr-2'></i>
                Stay Updated
              </h3>
              <p className='text-white/90 mb-6'>
                Subscribe to receive weekly curated resources for developers and
                designers, delivered directly to your inbox.
              </p>
              <a
                href='/contact'
                className='inline-block px-6 py-3 bg-white text-primary hover:bg-gray-100 rounded-lg transition-colors duration-200 font-medium'
              >
                Subscribe Now <i className='fas fa-arrow-right ml-1'></i>
              </a>
            </div>
          </div>
        </div>

        {/* Add a simplified navigation for mobile only */}
        <div className='block md:hidden mt-8 pt-6 border-t border-gray-200 dark:border-gray-700'>
          <h3 className='text-lg font-bold mb-3 text-primary'>
            Jump to Section
          </h3>
          <div className='grid grid-cols-2 gap-3'>
            <a
              href='#aws'
              className='p-3 bg-white/60 dark:bg-gray-800/60 rounded-md shadow-sm backdrop-blur-sm text-center text-gray-600 dark:text-gray-300 hover:text-primary'
            >
              <i className='fab fa-aws block text-[#FF9900] text-xl mb-1'></i>
              <span className='text-sm'>AWS Tools</span>
            </a>
            <a
              href='#development'
              className='p-3 bg-white/60 dark:bg-gray-800/60 rounded-md shadow-sm backdrop-blur-sm text-center text-gray-600 dark:text-gray-300 hover:text-primary'
            >
              <i className='fas fa-code block text-primary text-xl mb-1'></i>
              <span className='text-sm'>Dev Resources</span>
            </a>
            <a
              href='#design'
              className='p-3 bg-white/60 dark:bg-gray-800/60 rounded-md shadow-sm backdrop-blur-sm text-center text-gray-600 dark:text-gray-300 hover:text-primary'
            >
              <i className='fas fa-palette block text-[#F24E1E] text-xl mb-1'></i>
              <span className='text-sm'>Design</span>
            </a>
            <a
              href='#learning'
              className='p-3 bg-white/60 dark:bg-gray-800/60 rounded-md shadow-sm backdrop-blur-sm text-center text-gray-600 dark:text-gray-300 hover:text-primary'
            >
              <i className='fas fa-graduation-cap block text-[#14bf96] text-xl mb-1'></i>
              <span className='text-sm'>Learning</span>
            </a>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default Resources
