import React from 'react'
import ThreeBackground from '../components/experimental/three-background/ThreeBackground'
import TypingTitle from '../components/experimental/typing-title/TypingTitle'
import TiltedProjectCard from '../components/experimental/title-project-card/TiltedProjectCard'
import ParallaxSection from '../components/experimental/parallax-scroll/ParallaxSection'
import ObjectViewer from '../components/experimental/3d-object-viewer/ObjectViewer'
import FluidBackground from '../components/experimental/fluid-background/FluidBackground'
import MorphSvg, {
  MorphShapes
} from '../components/experimental/svg-morph/MorphSvg'

// Updated ExperimentalPage to showcase our implemented features
const ExperimentalPage: React.FC = () => {
  // Demo parallax layers
  const parallaxLayers = [
    {
      image: 'https://images.unsplash.com/photo-1519681393784-d120267933ba',
      speed: 0.2,
      zIndex: 1,
      opacity: 1
    },
    {
      image: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e',
      speed: 0.5,
      zIndex: 2,
      opacity: 0.7
    },
    {
      image: 'https://images.unsplash.com/photo-1510784722466-f2aa9c52fff6',
      speed: 0.9,
      zIndex: 3,
      opacity: 0.5
    }
  ]

  // SVG morph paths to cycle through
  const morphPaths = [
    MorphShapes.Circle,
    MorphShapes.Square,
    MorphShapes.Triangle,
    MorphShapes.Star,
    MorphShapes.Heart,
    MorphShapes.Arrow
  ]

  // Demo projects for cards with enhanced content
  const demoProjects = [
    {
      title: 'Interactive Particles',
      description:
        'Canvas-based particle system with mouse interaction and connecting lines. Creates an engaging network visualization effect.',
      tags: ['Canvas', 'Animation', 'Interactive'],
      link: '#feature-particles'
    },
    {
      title: 'Typing Animation',
      description:
        'Smart typing animation with custom cursor and dynamic text transitions. Perfect for creating engaging hero sections.',
      tags: ['Text Effects', 'Animation'],
      link: '#feature-typing'
    },
    {
      title: 'Interactive Cards',
      description:
        'Cards with hover animations, color schemes, and 3D-like interactions. Lightweight alternative to tilt libraries.',
      tags: ['UI/UX', 'Interaction'],
      link: '#feature-cards'
    }
  ]

  return (
    <div className="min-h-screen pl-[125px]">
      {/* Hero Section with interactive particle background */}
      <section
        id="feature-particles"
        className="h-screen relative flex flex-col items-center justify-center"
      >
        <ThreeBackground />

        <div className="text-center px-page-mobile md:px-page-desktop relative z-10">
          <h1 className="text-5xl md:text-7xl font-bold text-white mb-6">
            Experimental Features
          </h1>
          <div className="max-w-3xl mx-auto">
            {/* Showcase the typing effect */}
            <div id="feature-typing" className="mb-10">
              <div className="h-16 flex items-center justify-center">
                <TypingTitle
                  phrases={[
                    'Interactive Particles',
                    'Dynamic Typography',
                    'Responsive Animations',
                    'Visual Effects'
                  ]}
                  typingSpeed={80}
                  deleteSpeed={40}
                  delayTime={1500}
                />
              </div>
            </div>

            <p className="text-xl md:text-2xl text-white mb-8 max-w-3xl mx-auto">
              Explore our custom-built fallback features that don't rely on
              external libraries. These lightweight implementations provide
              visual impact without heavy dependencies.
            </p>
          </div>
        </div>
      </section>

      {/* Original Features Showcase Section */}
      <section className="py-20 bg-white dark:bg-gray-800">
        <div className="container mx-auto px-page-mobile md:px-page-desktop">
          <h2 className="text-4xl font-bold mb-10 text-center">
            Original Features
          </h2>

          <div className="max-w-3xl mx-auto mb-12 text-center">
            <p className="text-lg text-gray-600 dark:text-gray-300">
              These features were built with vanilla JavaScript and React,
              making them lightweight and dependency-free. Perfect for
              portfolios that need to load quickly while still providing visual
              impact.
            </p>
          </div>

          {/* Features in a more detailed layout */}
          <div className="max-w-5xl mx-auto space-y-16">
            {/* Feature 1: Interactive Particles */}
            <div>
              <div className="flex flex-col md:flex-row gap-8 items-center">
                <div className="md:w-1/2">
                  <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
                    Interactive Particles
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">
                    Canvas-based animation with dynamic particles that respond
                    to mouse movements. Particles connect with lines when
                    they're close to each other, creating an engaging network
                    effect.
                  </p>
                  <ul className="list-disc pl-5 text-gray-600 dark:text-gray-300 space-y-1">
                    <li>Smooth animation with requestAnimationFrame</li>
                    <li>Responsive to window resizing</li>
                    <li>Mouse interaction with proximity detection</li>
                    <li>Customizable colors, speeds, and densities</li>
                  </ul>
                </div>
                <div className="md:w-1/2 bg-gray-100/60 dark:bg-gray-700/60 rounded-lg p-6 shadow-lg backdrop-blur-sm">
                  <div className="aspect-video flex items-center justify-center bg-gray-200 dark:bg-gray-600 rounded">
                    <p className="text-center px-page-mobile md:px-page-desktop">
                      Interactive particle effect visible in the hero background
                      at the top of this page
                      <span className="block mt-2 text-blue-500 dark:text-blue-400">
                        ↑ Scroll up to see it in action
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Feature 2: Typing Animation */}
            <div>
              <div className="flex flex-col md:flex-row-reverse gap-8 items-center">
                <div className="md:w-1/2">
                  <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
                    Dynamic Typography
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">
                    Enhanced typing animation with gradient text effects, a
                    realistic cursor, and natural typing/deleting rhythms. The
                    cursor only blinks when paused between phrases for a more
                    authentic effect.
                  </p>
                  <ul className="list-disc pl-5 text-gray-600 dark:text-gray-300 space-y-1">
                    <li>Customizable typing and deletion speeds</li>
                    <li>Adjustable delay between phrases</li>
                    <li>Smooth transitions between text sequences</li>
                    <li>Supports rich text formatting and gradients</li>
                  </ul>
                </div>
                <div className="md:w-1/2 bg-gray-100/60 dark:bg-gray-700/60 rounded-lg p-6 shadow-lg backdrop-blur-sm">
                  <div className="h-40 flex items-center justify-center">
                    <div className="w-full typing-container">
                      <TypingTitle
                        phrases={[
                          'Smart Typing Effect',
                          'Gradient Text Animation',
                          'Custom Blinking Cursor'
                        ]}
                        typingSpeed={60}
                        deleteSpeed={30}
                        delayTime={1000}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Feature 3: Interactive Cards */}
            <div>
              <div className="flex flex-col md:flex-row gap-8 items-center">
                <div className="md:w-1/2">
                  <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
                    Interactive Card Components
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">
                    Cards with dynamic hover effects, color schemes, and subtle
                    3D-like interactions. These components are lightweight
                    replacements for tilt effects libraries, providing similar
                    visual impact with vanilla CSS and React.
                  </p>
                  <ul className="list-disc pl-5 text-gray-600 dark:text-gray-300 space-y-1">
                    <li>Hover animations with transform effects</li>
                    <li>Dynamic color schemes based on content</li>
                    <li>Tag system for categorizing cards</li>
                    <li>Responsive design for all screen sizes</li>
                  </ul>
                </div>
                <div className="md:w-1/2 bg-gray-100/60 dark:bg-gray-700/60 rounded-lg p-6 shadow-lg backdrop-blur-sm">
                  <div className="space-y-4">
                    {demoProjects.map((project, index) => (
                      <a key={index} href={project.link} className="block">
                        <TiltedProjectCard {...project} index={index} />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* New Feature: Parallax Section */}
      <section className="py-20 bg-gray-50 dark:bg-gray-900">
        <div className="container mx-auto px-page-mobile md:px-page-desktop">
          <h2 className="text-4xl font-bold mb-10 text-center">
            Parallax Scrolling
          </h2>

          <div className="max-w-3xl mx-auto mb-12 text-center">
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Create depth and immersion with multi-layer parallax scrolling.
              This technique creates a 3D effect by moving background elements
              at different speeds as the user scrolls.
            </p>
          </div>

          <div>
            <div className="max-w-5xl mx-auto rounded-lg overflow-hidden shadow-xl">
              <ParallaxSection layers={parallaxLayers} height="50vh" />
            </div>

            <div className="max-w-3xl mx-auto mt-8 bg-white/60 dark:bg-gray-800/60 p-6 rounded-lg shadow-lg backdrop-blur-sm">
              <h3 className="text-xl font-bold mb-2">How It Works</h3>
              <p className="mb-4 text-gray-600 dark:text-gray-300">
                The parallax effect is achieved by moving multiple background
                layers at different speeds as the user scrolls. Distant elements
                move slower than closer elements, creating a sense of depth.
              </p>
              <div className="bg-gray-100/60 dark:bg-gray-700/60 p-4 rounded-lg backdrop-blur-sm">
                <pre className="text-xs md:text-sm overflow-x-auto bg-gray-100/60 dark:bg-gray-800/60 backdrop-blur-sm">
                  {`<ParallaxSection
  layers={[
    { image: '/bg.jpg', speed: 0.2, zIndex: 1 },  // Moves slowest (far away)
    { image: '/mid.png', speed: 0.5, zIndex: 2 }, // Moves at medium speed
    { image: '/fg.png', speed: 0.8, zIndex: 3 }   // Moves fastest (close)
  ]}
  height="50vh"
/>`}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* New Feature: 3D Object Viewer and Fluid Background */}
      <section className="py-20 bg-white dark:bg-gray-800 relative overflow-hidden">
        {/* Fluid Background */}
        <div className="absolute inset-0 opacity-30">
          <FluidBackground colorA="#6366f1" colorB="#3b82f6" speed={0.003} />
        </div>

        <div className="container mx-auto px-page-mobile md:px-page-desktop relative z-10">
          <h2 className="text-4xl font-bold mb-10 text-center">
            Interactive WebGL Features
          </h2>

          <div className="max-w-3xl mx-auto mb-12 text-center">
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Create engaging user experiences with interactive 3D objects and
              fluid animations. These features leverage the power of WebGL and
              canvas to create stunning visual effects.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-5xl mx-auto">
            {/* 3D Object Viewer */}
            <div className="bg-white/60 dark:bg-gray-700/60 p-6 rounded-lg shadow-lg backdrop-blur-sm">
              <h3 className="text-xl font-bold mb-4 text-center">
                3D Object Viewer
              </h3>
              <div className="aspect-square">
                <ObjectViewer
                  width="100%"
                  height="100%"
                  modelColor="#4f46e5"
                  backgroundColor="#f8fafc"
                />
              </div>
              <div className="mt-4">
                <p className="text-gray-600 dark:text-gray-300 text-sm">
                  An interactive 3D object rendered with canvas. Click and drag
                  to rotate the model. When not interacting, the object
                  auto-rotates for continuous visual interest.
                </p>
              </div>
            </div>

            {/* Fluid Background */}
            <div className="bg-white/60 dark:bg-gray-700/60 p-6 rounded-lg shadow-lg backdrop-blur-sm">
              <h3 className="text-xl font-bold mb-4 text-center">
                Fluid Background
              </h3>
              <div className="aspect-square relative overflow-hidden rounded-lg">
                <FluidBackground
                  colorA="#8b5cf6"
                  colorB="#ec4899"
                  speed={0.01}
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <p className="text-white font-bold text-xl bg-black bg-opacity-30 px-4 py-2 rounded">
                    Interactive Fluid Effect
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-gray-600 dark:text-gray-300 text-sm">
                  An organic fluid animation that responds to user interaction.
                  Click or move your pointer within the container to create
                  ripple effects in the animation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* New Feature: SVG Morphing */}
      <section className="py-20 bg-gray-50 dark:bg-gray-900">
        <div className="container mx-auto px-page-mobile md:px-page-desktop">
          <h2 className="text-4xl font-bold mb-10 text-center">
            SVG Path Morphing
          </h2>

          <div className="max-w-3xl mx-auto mb-12 text-center">
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Create smooth transitions between different shapes with SVG path
              morphing. Perfect for interactive icons, loading indicators, and
              animated illustrations.
            </p>
          </div>

          <div className="max-w-5xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* SVG Morph Demo 1 */}
              <div className="bg-white/60 dark:bg-gray-800/60 p-6 rounded-lg shadow-lg flex flex-col items-center backdrop-blur-sm">
                <h3 className="text-lg font-bold mb-4">Basic Shapes</h3>
                <div className="p-4">
                  <MorphSvg
                    paths={morphPaths}
                    width={200}
                    height={200}
                    duration={1000}
                    strokeColor="#3b82f6"
                    strokeWidth={3}
                    fillColor="none"
                  />
                </div>
                <p className="text-gray-600 dark:text-gray-300 text-sm mt-2 text-center">
                  Morphing between geometric shapes with a clean outline style
                </p>
              </div>

              {/* SVG Morph Demo 2 */}
              <div className="bg-white/60 dark:bg-gray-800/60 p-6 rounded-lg shadow-lg flex flex-col items-center backdrop-blur-sm">
                <h3 className="text-lg font-bold mb-4">Filled Shapes</h3>
                <div className="p-4">
                  <MorphSvg
                    paths={[
                      MorphShapes.Circle,
                      MorphShapes.Square,
                      MorphShapes.Triangle
                    ]}
                    width={200}
                    height={200}
                    duration={1500}
                    strokeColor="#ec4899"
                    strokeWidth={2}
                    fillColor="rgba(236, 72, 153, 0.2)"
                  />
                </div>
                <p className="text-gray-600 dark:text-gray-300 text-sm mt-2 text-center">
                  Semi-transparent fill adds depth to the animation
                </p>
              </div>

              {/* SVG Morph Demo 3 */}
              <div className="bg-white/60 dark:bg-gray-800/60 p-6 rounded-lg shadow-lg flex flex-col items-center backdrop-blur-sm">
                <h3 className="text-lg font-bold mb-4">Icon Transitions</h3>
                <div className="p-4">
                  <MorphSvg
                    paths={[
                      MorphShapes.Arrow,
                      MorphShapes.Star,
                      MorphShapes.Heart
                    ]}
                    width={200}
                    height={200}
                    duration={800}
                    strokeColor="#10b981"
                    strokeWidth={4}
                    fillColor="rgba(16, 185, 129, 0.1)"
                  />
                </div>
                <p className="text-gray-600 dark:text-gray-300 text-sm mt-2 text-center">
                  Perfect for interactive icons and UI elements
                </p>
              </div>
            </div>

            <div className="max-w-3xl mx-auto mt-12 bg-white/60 dark:bg-gray-800/60 p-6 rounded-lg shadow-lg backdrop-blur-sm">
              <h3 className="text-xl font-bold mb-2">Implementation</h3>
              <p className="mb-4 text-gray-600 dark:text-gray-300">
                The SVG morphing animation smoothly interpolates between
                different path definitions, creating fluid transitions between
                shapes with minimal code.
              </p>
              <div className="bg-gray-100/60 dark:bg-gray-700/60 p-4 rounded-lg backdrop-blur-sm">
                <pre className="text-xs md:text-sm overflow-x-auto bg-gray-100/60 dark:bg-gray-800/60 backdrop-blur-sm">
                  {`<MorphSvg
  paths={[
    MorphShapes.Circle,
    MorphShapes.Square,
    MorphShapes.Star,
    MorphShapes.Heart
  ]}
  duration={1000}
  strokeColor="#3b82f6"
  strokeWidth={2}
  fillColor="rgba(59, 130, 246, 0.1)"
/>`}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Implementation Guide */}
      <section className="py-20 bg-white dark:bg-gray-800">
        <div className="container mx-auto px-page-mobile md:px-page-desktop">
          <h2 className="text-4xl font-bold mb-10 text-center">
            Implementation Guide
          </h2>

          <div className="bg-white/60 dark:bg-gray-800/60 p-6 rounded-lg shadow-lg max-w-4xl mx-auto backdrop-blur-sm">
            <h3 className="text-xl font-bold mb-4">About These Features</h3>
            <p className="mb-4">
              All features showcased here are implemented without external
              dependencies using:
            </p>
            <ul className="list-disc pl-5 mb-6 space-y-2">
              <li>
                <strong>HTML Canvas API</strong> - For interactive particle
                backgrounds, 3D objects and fluid effects
              </li>
              <li>
                <strong>SVG</strong> - For morphing animations and vector
                graphics
              </li>
              <li>
                <strong>React Hooks</strong> - For state management and
                animations
              </li>
              <li>
                <strong>CSS Animations</strong> - For smooth transitions and
                effects
              </li>
              <li>
                <strong>Intersection Observer</strong> - For scroll-triggered
                animations
              </li>
              <li>
                <strong>Custom React Components</strong> - Reusable UI elements
              </li>
            </ul>

            <div className="bg-gray-100/60 dark:bg-gray-800 p-4 rounded mb-6">
              <h4 className="font-bold mb-2">No Dependencies Required!</h4>
              <p>
                These features work as fallbacks while we resolve external
                package dependencies, but they're impressive enough to use as
                primary features in many cases.
              </p>
            </div>

            <div className="border-t border-gray-200 dark:border-gray-600 pt-6 mt-6">
              <h4 className="font-bold mb-3">Where to Find the Code:</h4>
              <ul className="space-y-1 font-mono text-sm">
                <li>
                  <span className="text-green-600 dark:text-green-400">→</span>{' '}
                  src/components/experimental/three-background/ThreeBackground.tsx
                </li>
                <li>
                  <span className="text-green-600 dark:text-green-400">→</span>{' '}
                  src/components/experimental/typing-title/TypingTitle.tsx
                </li>
                <li>
                  <span className="text-green-600 dark:text-green-400">→</span>{' '}
                  src/components/experimental/title-project-card/TiltedProjectCard.tsx
                </li>
                <li>
                  <span className="text-green-600 dark:text-green-400">→</span>{' '}
                  src/components/experimental/parallax-scroll/ParallaxSection.tsx
                </li>
                <li>
                  <span className="text-green-600 dark:text-green-400">→</span>{' '}
                  src/components/experimental/3d-object-viewer/ObjectViewer.tsx
                </li>
                <li>
                  <span className="text-green-600 dark:text-green-400">→</span>{' '}
                  src/components/experimental/fluid-background/FluidBackground.tsx
                </li>
                <li>
                  <span className="text-green-600 dark:text-green-400">→</span>{' '}
                  src/components/experimental/svg-morph/MorphSvg.tsx
                </li>
                <li>
                  <span className="text-green-600 dark:text-green-400">→</span>{' '}
                  src/pages/HeroHome.tsx
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Simple styling for the typing container */}
      <style>
        {`
          /* Prevent typing animation reflow */
          .typing-container {
            min-height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
          }
        `}
      </style>
    </div>
  )
}

export default ExperimentalPage
