import React from 'react'
import TimelineSectionRelume from '../components/relume/TimelineSection'
import SimpleBadge from '../components/ui/SimpleBadge'
import SimpleButton from '../components/ui/SimpleButton'

// Sample data for timeline could be later moved to a dedicated file
const contactInfo = [
  { icon: 'map-marker-alt', text: 'Alexandria, VA' },
  { icon: 'phone', text: '(703) 731-7528', href: 'tel:7037317528' },
  {
    icon: 'envelope',
    text: 'hello@jeffknowlesjr.com',
    href: 'mailto:hello@jeffknowlesjr.com'
  },
  {
    icon: 'linkedin',
    text: 'LinkedIn',
    href: 'https://linkedin.com/in/jeffknowlesjr'
  }
]

const skillCategories = [
  {
    title: 'Development',
    color: 'primary',
    skills: ['JavaScript', 'React', 'Node.js', 'TypeScript', 'HTML/CSS']
  },
  {
    title: 'Design & CMS',
    color: 'secondary',
    skills: ['Webflow', 'WordPress', 'Tailwind CSS', 'UI/UX Design']
  },
  {
    title: 'Tools & Analytics',
    color: 'complement',
    skills: ['Google Analytics', 'AWS', 'Git', 'Obsidian']
  }
]

const education = [
  {
    title: 'Bachelor of Science',
    subtitle: 'Computer Science, Virginia Tech'
  },
  {
    title: 'AWS Certified Solutions Architect',
    subtitle: 'Associate Level'
  },
  {
    title: 'Google Analytics Certification',
    subtitle: 'Advanced Analytics'
  }
]

const AboutRelume: React.FC = () => {
  return (
    <div className="min-h-screen pb-16">
      {/* Header - Using standard elements with Tailwind */}
      <header className="mb-12 text-center pt-16 pb-8 bg-gradient-to-r from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900">
        <div className="container mx-auto">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            About Jeff Knowles Jr.
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Full-Stack Web Developer based in Alexandria, VA
          </p>
        </div>
      </header>

      {/* Main Content */}
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Main Content - 8 columns on large screens */}
          <div className="lg:col-span-8 order-2 lg:order-1">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-8">
              <h2 className="text-xl font-bold mb-4 pb-2 border-b border-gray-200 dark:border-gray-700">
                About
              </h2>
              <p className="text-gray-700 dark:text-gray-300 mb-4">
                I'm a self-motivated, full-stack web developer and creative
                problem solver with a keenness for design. I bring deep
                experience in web development, JavaScript, and marketing
                analytics to every project I undertake.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mb-4">
                Throughout my career, I've focused on creating effective,
                user-centered digital experiences that combine technical
                excellence with attractive design. I'm passionate about building
                solutions that are not only functional but also intuitive and
                enjoyable to use.
              </p>
              <p className="text-gray-700 dark:text-gray-300">
                I'm seeking a collaborative and inclusive workplace where I can
                write great code, contribute to meaningful projects, and
                continue to learn and grow as a developer.
              </p>
              <div className="mt-6">
                <SimpleButton variant="primary">Contact Me</SimpleButton>
              </div>
            </div>

            {/* Timeline Section - Use the new component */}
            <TimelineSectionRelume />
          </div>

          {/* Sidebar - 4 columns on large screens */}
          <aside className="lg:col-span-4 order-1 lg:order-2">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-8">
              <h2 className="text-xl font-bold mb-4 pb-2 border-b border-gray-200 dark:border-gray-700">
                Contact Information
              </h2>
              <ul className="space-y-3">
                {contactInfo.map((item, index) => (
                  <li key={index} className="flex items-center">
                    <i className={`fas fa-${item.icon} text-primary mr-3`}></i>
                    {item.href ? (
                      <a
                        href={item.href}
                        target={item.icon === 'linkedin' ? '_blank' : undefined}
                        rel={
                          item.icon === 'linkedin'
                            ? 'noopener noreferrer'
                            : undefined
                        }
                        className="hover:text-primary"
                      >
                        {item.text}
                      </a>
                    ) : (
                      <span>{item.text}</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-8">
              <h2 className="text-xl font-bold mb-4 pb-2 border-b border-gray-200 dark:border-gray-700">
                Skills & Expertise
              </h2>
              <div className="space-y-4">
                {skillCategories.map((category, index) => (
                  <div key={index}>
                    <h3 className={`font-medium text-${category.color} mb-2`}>
                      {category.title}
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {category.skills.map((skill, skillIndex) => (
                        <SimpleBadge
                          key={skillIndex}
                          className={`px-3 py-1 bg-${category.color}/10 text-${category.color} text-xs rounded-full`}
                        >
                          {skill}
                        </SimpleBadge>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold mb-4 pb-2 border-b border-gray-200 dark:border-gray-700">
                Education & Certifications
              </h2>
              <ul className="space-y-4">
                {education.map((item, index) => (
                  <li key={index}>
                    <h3 className="font-medium">{item.title}</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {item.subtitle}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  )
}

export default AboutRelume
