import React from 'react'
import { TypeAnimation } from 'react-type-animation'
import { useTheme } from '../contexts/ThemeContext'
import KnowledgeBase from '../components/home/KnowledgeBase'
import GlobalLayout from '../components/layout/GlobalLayout'

const HomePage: React.FC = () => {
  const { theme } = useTheme()

  return (
    <GlobalLayout>
      <div className='max-w-6xl mx-auto px-2 sm:px-page-mobile md:px-page-desktop py-4 sm:py-6'>
        {/* Hero Section */}
        <div className='pt-8 sm:pt-16 md:pt-20 lg:pt-24 pb-16 sm:pb-24 md:pb-32 lg:pb-40'>
          <div className='pb-8 sm:pb-16 md:pb-20 lg:pb-24'>
            <h2
              className={`hero-title text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-4 sm:mb-8 md:mb-12 lg:mb-16 transition-colors duration-300 ${
                theme === 'dark' ? 'text-white' : 'text-gray-800'
              }`}
            >
              Hello, I'm
              <br />
              <span className='text-[#52babb] block mt-2 whitespace-nowrap'>
                Jeff Knowles Jr
              </span>
            </h2>
            <div className='pl-4 sm:pl-8 border-l-2 border-[#52babb]/20 space-y-4 sm:space-y-8 md:space-y-12 lg:space-y-16'>
              <div>
                <p
                  className={`transition-colors duration-300 ${
                    theme === 'dark' ? 'text-white/95' : 'text-gray-800'
                  } text-xl md:text-2xl lg:text-3xl font-light`}
                >
                  <span className='whitespace-nowrap'>Digital Development</span>
                  <span
                    className={`block transition-colors duration-300 ${
                      theme === 'dark' ? 'text-white/60' : 'text-gray-600'
                    } text-lg md:text-xl lg:text-2xl mt-2 sm:mt-4 md:mt-6 lg:mt-8`}
                  >
                    Web Designer & Marketing Technology Specialist
                  </span>
                </p>
              </div>

              <div
                className={`transition-colors duration-300 ${
                  theme === 'dark' ? 'text-white/90' : 'text-gray-700'
                } text-xl md:text-2xl lg:text-3xl font-light`}
              >
                Built with{' '}
                <span className='inline-block w-[200px] sm:w-[250px] md:w-[400px] lg:w-[500px]'>
                  <TypeAnimation
                    sequence={[
                      'React and TypeScript',
                      2000,
                      'TailwindCSS and GSAP',
                      2000,
                      'Modern Web Technologies',
                      2000
                    ]}
                    wrapper='span'
                    speed={50}
                    repeat={Infinity}
                    className='font-medium text-[#52babb] whitespace-nowrap'
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Services Section */}
        <div id='services'>
          <h2 className='text-2xl md:text-3xl font-bold text-gray-800 dark:text-white mb-4 sm:mb-6 md:mb-8 pt-4 sm:pt-8'>
            Development Services
          </h2>
          <p className='mt-2 sm:mt-3 text-base md:text-lg text-gray-600 dark:text-gray-300 max-w-2xl mb-4 sm:mb-8'>
            Comprehensive solutions for modern web applications, from
            architecture to deployment.
          </p>
          <KnowledgeBase />
        </div>
      </div>
    </GlobalLayout>
  )
}

export default HomePage
